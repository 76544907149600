import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RewardsModalTier } from '@box-types';
import { ConfigurationService } from '@box-core/services';
import { isPrerenderBrowser } from '@box/utils';
import { UserEventsService } from '@box-core/services/user-events.service';
import { maxBy } from 'lodash-es';
import orderBy from 'lodash-es/orderBy';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable()
export class RewardsBalanceBadgeService {
  constructor(
    private router: Router,
    private configService: ConfigurationService,
    private userEventsService: UserEventsService,
    private globalStateService: GlobalStateService
  ) {}

  public navigateToRewards(): void {
    void this.router.navigate(['/rewards']);
  }

  public navigateToCoupons(): void {
    void this.router.navigate(['/rewards/coupons']);
  }

  public shouldShowTutorial(): boolean {
    if (isPrerenderBrowser(window)) return false;
    const isInHomePage = this.router.url.includes('/home');
    if (!isInHomePage) return false;
    return Boolean(this.getRewardTierSeen());
  }

  public updateUserEventHistory(): void {
    const tierSeen = this.getRewardTierSeen();
    if (!tierSeen) return;
    this.userEventsService.appendToEventHistory(tierSeen.key);
  }

  public getRewardTierSeen(): RewardsModalTier {
    const unseenRewardTiers = this.getPendingRewardTiers();
    const userEarnedAmount = this.globalStateService.getUser().earnedRewardsInfo?.earnedEuros ?? 0;
    const earnedAmountInCents = Math.trunc(userEarnedAmount * 100);
    return unseenRewardTiers.find((tier) => tier.amount <= earnedAmountInCents);
  }

  private getPendingRewardTiers(): RewardsModalTier[] {
    const rewardsModalConfigTiers = this.configService.getConfiguration().userEventHistoryKeys?.rewardsModalTiers;
    if (!rewardsModalConfigTiers?.length) return [];
    const userEventHistory = this.userEventsService.getUserEventHistory();
    if (!userEventHistory) return [];

    const rewardsModalConfigTierKeys = rewardsModalConfigTiers.map((tier) => tier.key);
    const rewardsHistoryEvents = userEventHistory.filter((event) => {
      return rewardsModalConfigTierKeys.includes(event.key);
    });

    const maxTierSeen = maxBy(rewardsModalConfigTiers, (tier) => {
      const correspondingEvent = rewardsHistoryEvents.find((event) => tier.key === event.key);
      if (correspondingEvent) return tier.amount;
    });

    const sortedRewardsModalConfigTiers = orderBy(rewardsModalConfigTiers, (tier) => tier.amount, 'desc');
    if (!maxTierSeen) return sortedRewardsModalConfigTiers;

    return sortedRewardsModalConfigTiers.filter((tier) => tier.amount > maxTierSeen.amount);
  }
}
