import { Injector, Injectable } from '@angular/core';
import { CartService } from '@box-core/services/cart.service';
import { BehaviorSubject } from 'rxjs';
import {
  maxCartVolumeWillBeReached,
  maxCartQuantityForItemWillBeReached,
  remainingRequiredChoices,
  addInstanceToItem,
  updateInstanceToItem,
  removeInstanceFromItem,
  getOfferProductNamesWithRemainingChoices
} from '@box/utils';
import {
  Product,
  ProductInstance,
  Offer,
  OfferInstance,
  Cart,
  CartItemType,
  CartItemInstanceType,
  CartActionResponse,
  CartItemsServiceConfiguration,
  CartItemMyoDialogResponse
} from '@box-types';

export abstract class CartItemService<I, N> {
  protected cartService: CartService;
  protected cartSource: BehaviorSubject<Cart>;
  protected saveCartToStorage: boolean;

  constructor(injector: Injector) {
    this.cartService = injector.get(CartService);
    this.cartSource = this.cartService.cartSource;
    this.saveCartToStorage = true;
  }

  public setCartConfiguration(configuration: CartItemsServiceConfiguration): void {
    this.cartSource = configuration.cartSource;
    this.saveCartToStorage = configuration.saveCartToStorage;
  }

  public addItem(item: CartItemType<I>, instance: CartItemInstanceType<N>): CartActionResponse {
    const cartItem = this.getCartItem(item);
    const itemQuantity = cartItem.cartQuantity + instance.quantity;

    if (!this.addItemSpecificBusinessLogic(item, instance)) return;
    if (!this.checkInstanceRemainingChoicesAndShowDialog(instance)) return 'CHOICES_THRESHOLD_NOT_REACHED';
    if (!this.checkCartVolumeAndShowDialog(item, instance)) return 'CART_LIMIT_REACHED';
    if (!this.checkCartItemLimitAndShowDialog(itemQuantity, cartItem)) return 'ITEM_LIMIT_REACHED';
    const updatedCart = this.addNewItemOrNewInstanceToCart(cartItem, instance);
    this.cartService.calculatePricesAndUpdateCart(updatedCart, this.cartSource);
    if (this.saveCartToStorage) this.cartService.setLocalCartCollections(updatedCart);
    return 'ITEM_ADDED';
  }

  public updateItem(
    item: CartItemType<I>,
    instance: CartItemInstanceType<N>,
    updatedInstance: CartItemInstanceType<N>
  ): CartActionResponse {
    const cartItemIndex = this.getCartItemIndex(item);
    if (cartItemIndex === -1) return;
    const cartItem = this.getCartItem(item);
    if (!this.checkInstanceRemainingChoicesAndShowDialog(instance)) return 'CHOICES_THRESHOLD_NOT_REACHED';
    const instanceQuantityDifference = updatedInstance.quantity - instance.quantity;
    if (instanceQuantityDifference > 0) {
      if (!this.checkCartVolumeAndShowDialog(item, instance)) return 'CART_LIMIT_REACHED';
    }
    const newItemCartQuantity = cartItem.cartQuantity + instanceQuantityDifference;
    if (!this.checkCartItemLimitAndShowDialog(newItemCartQuantity, cartItem)) return 'ITEM_LIMIT_REACHED';
    const updatedCart = this.updateItemInstance(cartItem, instance, updatedInstance);
    this.cartService.calculatePricesAndUpdateCart(updatedCart, this.cartSource);
    if (this.saveCartToStorage) this.cartService.setLocalCartCollections(updatedCart);
    return 'ITEM_UPDATED';
  }

  public removeItem(item: CartItemType<I>, instance: CartItemInstanceType<N>): CartActionResponse {
    const cartItemIndex = this.getCartItemIndex(item);
    if (cartItemIndex === -1) return;
    const cartItem = this.getCartItem(item);
    if (!cartItem?.cartInstances?.length) return;
    const updatedCart = this.removeItemInstance(item, instance);
    this.cartService.calculatePricesAndUpdateCart(updatedCart, this.cartSource);
    if (this.saveCartToStorage) this.cartService.setLocalCartCollections(updatedCart);
    return 'ITEM_REMOVED';
  }

  private checkCartVolumeAndShowDialog(item: CartItemType<I>, instance: CartItemInstanceType<N>): boolean {
    const volume = (item.volume ?? 0) * instance.quantity;
    if (maxCartVolumeWillBeReached(this.cartSource.getValue(), volume)) {
      this.cartService.showMaxCartLimitReachedDialog();
      return false;
    }
    return true;
  }

  private checkCartItemLimitAndShowDialog(quantity: number, item: CartItemType<I>): boolean {
    const maxQuantityReached = maxCartQuantityForItemWillBeReached(quantity, item);
    if (maxQuantityReached) {
      this.cartService.showMaxItemLimitReachedDialog();
      return false;
    }
    return true;
  }

  public getCart(): Cart {
    return this.cartSource.getValue();
  }

  public handleItemMyoResponse(response: CartItemMyoDialogResponse<I, N>): void {
    if (!response) return;
    const { editMode, item, itemInstanceToEdit, itemInstance } = response;
    if (response.remove) return void this.removeItem(item, itemInstance);

    editMode ? this.updateItem(item, itemInstanceToEdit, itemInstance) : this.addItem(item, itemInstance);
  }

  abstract getItemType(): 'product' | 'offer';
  abstract addItemSpecificBusinessLogic(item: CartItemType<I>, instance: CartItemInstanceType<N>): boolean;
  abstract getCartItemIndex(item: CartItemType<I>): number;
  abstract getCartItem(item: CartItemType<I>): CartItemType<I>;
  abstract checkInstanceRemainingChoicesAndShowDialog(instance: CartItemInstanceType<N>): boolean;
  abstract addNewItemOrNewInstanceToCart(item: CartItemType<I>, instance: CartItemInstanceType<N>): Cart;
  abstract updateItemInstance(
    item: CartItemType<I>,
    instance: CartItemInstanceType<N>,
    updatedInstance: CartItemInstanceType<N>
  ): Cart;
  abstract removeItemInstance(item: CartItemType<I>, instance: CartItemInstanceType<N>): Cart;
}

@Injectable({ providedIn: 'root' })
export class CartProductService extends CartItemService<Product, ProductInstance> {
  constructor(injector: Injector) {
    super(injector);
  }

  getItemType(): 'product' | 'offer' {
    return 'product';
  }

  addItemSpecificBusinessLogic(item: Product, instance: ProductInstance): boolean {
    return true;
  }

  getCartItemIndex(product: Product): number {
    const cart = this.getCart();
    return cart?.products?.findIndex((cartProduct) => cartProduct._id === product._id);
  }

  getCartItem(product: Product): Product {
    const cart = this.getCart();
    const cartItemIndex = this.getCartItemIndex(product);
    return cartItemIndex === -1 ? product : cart.products[cartItemIndex];
  }

  checkInstanceRemainingChoicesAndShowDialog(instance: ProductInstance): boolean {
    const remainingChoices = remainingRequiredChoices(instance);
    if (remainingChoices > 0) {
      this.cartService.showProductChoicesThresholdDialog(remainingChoices);
      return false;
    }
    return true;
  }

  addNewItemOrNewInstanceToCart(cartItem: Product, instance: ProductInstance): Cart {
    const cart = this.getCart();
    const cartProductIndex = this.getCartItemIndex(cartItem);
    const clonedCartProduct = addInstanceToItem(cartItem, instance);
    const updatedCart = { ...cart };
    if (cartProductIndex === -1) {
      updatedCart.products.push(clonedCartProduct);
    } else {
      updatedCart.products[cartProductIndex] = clonedCartProduct;
    }
    return updatedCart;
  }

  updateItemInstance(cartItem: Product, instance: ProductInstance, updatedInstance: ProductInstance): Cart {
    const cart = this.getCart();
    const cartProductIndex = this.getCartItemIndex(cartItem);
    const updatedCart = { ...cart };
    updatedCart.products[cartProductIndex] = updateInstanceToItem(cartItem, instance, updatedInstance);
    return updatedCart;
  }

  removeItemInstance(cartProduct: Product, instance: ProductInstance): Cart {
    const cart = this.getCart();
    const cartItemIndex = this.getCartItemIndex(cartProduct);
    const clonedCartProduct = removeInstanceFromItem(cartProduct, instance);
    const updatedCart = { ...cart };
    if (!clonedCartProduct.cartQuantity) {
      updatedCart.products.splice(cartItemIndex, 1);
    } else {
      updatedCart.products[cartItemIndex] = clonedCartProduct;
    }
    return updatedCart;
  }

  public cartProductWithSelectionsExists(product: Product): boolean {
    if (!product?.selections?.length) return false;
    const cartProducts = this.getCart().products;
    if (!cartProducts?.length) return false;
    return cartProducts.some((cartProduct) => cartProduct._id === product._id);
  }
}

@Injectable({ providedIn: 'root' })
export class CartOfferService extends CartItemService<Offer, OfferInstance> {
  constructor(injector: Injector) {
    super(injector);
  }

  getItemType(): 'product' | 'offer' {
    return 'offer';
  }

  addItemSpecificBusinessLogic(item: Offer, instance: OfferInstance): boolean {
    if (item.isDFY && this.cartHasDFYOffer()) return false;
    return this.areInstanceGroupsValid(instance);
  }

  areInstanceGroupsValid(instance: OfferInstance): boolean {
    return instance?.groups?.every((group) => Boolean(group.selectedProduct));
  }

  getCartItemIndex(offer: Offer): number {
    const cart = this.getCart();
    return cart?.offers?.findIndex((item) => item._id === offer._id);
  }

  getCartItem(offer: Offer): Offer {
    const cart = this.cartSource.getValue();
    const cartItemIndex = this.getCartItemIndex(offer);
    return cartItemIndex === -1 ? offer : cart.offers[cartItemIndex];
  }

  checkInstanceRemainingChoicesAndShowDialog(instance: OfferInstance): boolean {
    const incompleteProducts = getOfferProductNamesWithRemainingChoices(instance);
    if (incompleteProducts.length > 0) {
      this.cartService.showOfferChoicesThresholdDialog(incompleteProducts);
      return false;
    }
    return true;
  }

  addNewItemOrNewInstanceToCart(cartOffer: Offer, instance: OfferInstance): Cart {
    const cart = this.getCart();
    const cartOfferIndex = this.getCartItemIndex(cartOffer);
    const clonedCartOffer = addInstanceToItem(cartOffer, instance);
    const updatedCart = { ...cart };
    if (cartOfferIndex === -1) {
      updatedCart.offers.push(clonedCartOffer);
    } else {
      updatedCart.offers[cartOfferIndex] = clonedCartOffer;
    }
    return updatedCart;
  }

  updateItemInstance(cartOffer: Offer, instance: OfferInstance, updatedInstance: OfferInstance): Cart {
    const cart = this.getCart();
    const cartOfferIndex = this.getCartItemIndex(cartOffer);
    const clonedCartOffer = updateInstanceToItem(cartOffer, instance, updatedInstance);
    const updatedCart = { ...cart };
    updatedCart.offers[cartOfferIndex] = clonedCartOffer;
    return updatedCart;
  }

  removeItemInstance(cartOffer: Offer, instance: OfferInstance): Cart {
    const cart = this.getCart();
    const cartOfferIndex = this.getCartItemIndex(cartOffer);
    const clonedCartOffer = removeInstanceFromItem(cartOffer, instance);
    const updatedCart = { ...cart };
    if (!clonedCartOffer.cartQuantity) {
      cart.offers.splice(cartOfferIndex, 1);
    } else {
      cart.offers[cartOfferIndex] = clonedCartOffer;
    }
    return updatedCart;
  }

  public cartOfferExists(offer: Offer): boolean {
    if (!offer) return false;
    const cartOffers = this.getCart().offers;
    if (!cartOffers?.length) return false;
    return cartOffers.some((cartOffer) => cartOffer?._id === offer?._id);
  }

  public cartHasDFYOffer(): boolean {
    const cartOffers = this.getCart().offers;
    if (!cartOffers?.length) return false;
    return cartOffers.some((offer) => offer.couponType === 'dfu');
  }
}
