import { Component, Renderer2, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmDialogResponse,
  CartActionResponse,
  CartItemType,
  CartItemInstanceType,
  Offer,
  Product
} from '@box-types';
import { CartService, DialogService, ShopService } from '@box-core/services';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { SameMYODialogData, SameMYODialogResponse } from './same-myo-dialog.types';
import { CartItemService } from '@box-core/services/cart-item.service';

@Component({
  selector: 'same-myo-dialog',
  templateUrl: './same-myo-dialog.component.html',
  styleUrls: ['./same-myo-dialog.component.scss']
})
export class SameMYODialogComponent<I, N> extends BoxDialogWrapperComponent {
  public type: 'product' | 'offer';
  public cartItem: CartItemType<I>;
  public cartProduct: Product;
  public cartOffer: Offer;
  public isSuperMarket: boolean;
  private item: CartItemType<I>;
  private cartItemsService: CartItemService<I, N>;

  constructor(
    public override renderer: Renderer2,
    private cartService: CartService,
    private shopService: ShopService,
    private dialogRef: MatDialogRef<SameMYODialogComponent<I, N>>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private data: SameMYODialogData<I, N>
  ) {
    super(renderer);
    this.item = this.data.item;
    this.cartItemsService = this.data.cartItemService;
    this.type = this.cartItemsService.getItemType();
    this.setCartItems();
    this.isSuperMarket = this.shopService.shop.value.isSuperMarket;
  }

  public closeDialog(data?: SameMYODialogResponse) {
    this.dialogRef.close(data);
  }

  public openMYO(): void {
    this.closeDialog({ openMYO: true });
  }

  public onInstanceAdd(instance: unknown): void {
    const response: CartActionResponse = this.addInstanceToCart(instance as CartItemInstanceType<N>);
    if (response === 'CART_LIMIT_REACHED') return;
    if (response === 'ITEM_LIMIT_REACHED') return;
    if (response === 'CHOICES_THRESHOLD_NOT_REACHED') return;
    this.setCartItems();
  }

  public onInstanceRemove(instance: unknown): void {
    const removeCallBack = () => {
      this.removeInstanceFromCart(instance as CartItemInstanceType<N>);
      this.setCartItems();
    };

    if ((instance as CartItemInstanceType<N>).quantity === 1) return this.showRemovalConfirmationDialog(removeCallBack);
    return removeCallBack();
  }

  private showRemovalConfirmationDialog(callBack: () => void): void {
    const confirmConfig = {
      title: 'remove_from_cart',
      messages: ['this_action_will_remove_the_product_from_the_cart']
    };

    this.dialogService
      .openConfirmDialog(confirmConfig)
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (!data?.accepted) return;
        callBack();
        if (this.shouldShowClose()) return this.closeDialog();
      });
  }

  private addInstanceToCart(instance: CartItemInstanceType<N>): CartActionResponse {
    const item = this.cartItem;
    const itemInstance = { ...instance, quantity: 1 };
    return this.cartItemsService.addItem(item, itemInstance);
  }

  private removeInstanceFromCart(instance: CartItemInstanceType<N>): void {
    const item = this.cartItem;
    this.cartItemsService.removeItem(item, instance);
  }

  private setCartItems(): void {
    this.cartItem = this.getCartItem();
    if (this.type === 'product') {
      this.cartProduct = this.cartItem as unknown as Product;
    } else {
      this.cartOffer = this.cartItem as unknown as Offer;
    }
  }

  private getCartItem(): CartItemType<I> {
    return this.cartItemsService.getCartItem(this.item);
  }

  private shouldShowClose(): boolean {
    return !this.cartItem?.cartInstances?.length;
  }
}
