import { Category, Shop, GABeginCheckoutConfig, GAEcommerceItemConfig, PurchaseEvent, Cart } from '@box-types';
import { getPurchaseEventItems } from '../checkout';

export { getBeginCheckoutGAConfig };

type getBeginCheckoutGAConfigOptions = {
  totalPrice: number;
  shop: Shop;
  cart: Cart;
  purchaseEvent: PurchaseEvent;
  categories: Category[];
};

function getBeginCheckoutGAConfig(options: getBeginCheckoutGAConfigOptions): GABeginCheckoutConfig {
  if (!options.cart) return;
  const gaItems: GAEcommerceItemConfig[] = getPurchaseEventItems(
    options.shop,
    options.cart.offers,
    options.cart.products,
    options.categories,
    '',
    options.purchaseEvent
  );
  return {
    value: options.totalPrice / 100,
    currency: 'EUR',
    items: gaItems
  };
}
