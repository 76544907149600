import { offerIncludesOverrideOrderRule } from '../offers';
import { cartHasInactiveOffers } from './cart.utils';
import { GetTextByKeyType, Offer, CurrencyCode, Cart } from '@box-types';
import { currencyFormat, CurrencyFormatOptions } from '../core';

export {
  syncOfferCartQuantityWithCart,
  getCartOfferBeginPrice,
  getCartOfferFinalPrice,
  cartOverridesOrderMinimumPrice,
  getCartOfferMinOrderItemsPriceText,
  decorateOfferWithInactiveText,
  getCartInactiveOffers,
  getCartInactiveOffersMessage
};

function syncOfferCartQuantityWithCart(offer: Offer, cart: Cart): Offer {
  const cartOffers = cart.offers;
  const cartOffer = cartOffers.find((of) => of._id === offer._id);
  if (!cartOffer) return { ...offer, cartQuantity: 0 };
  return { ...offer, cartQuantity: cartOffer.cartQuantity };
}

function getCartOfferBeginPrice(offer: Offer): number {
  const instances = offer.cartInstances;
  if (!instances?.length) return 0;
  return instances.reduce((price, instance) => price + instance.quantity * instance.preOfferPrice, 0);
}

function getCartOfferFinalPrice(offer: Offer): number {
  const instances = offer.cartInstances;
  if (!instances?.length) return 0;
  return instances.reduce((price, instance) => price + instance.quantity * instance.price, 0);
}

function cartOverridesOrderMinimumPrice(cart: Cart): boolean {
  const offers = cart.offers;
  return offers.some((offer) => offerIncludesOverrideOrderRule(offer, 'minimumPrice'));
}

function getCartOfferMinOrderItemsPriceText(
  offer: Offer,
  cartPrice: number,
  translateFn: GetTextByKeyType,
  currencyCode: CurrencyCode
): string {
  const { minOrderItemsPrice } = offer;
  if (!minOrderItemsPrice || minOrderItemsPrice <= cartPrice) return;
  const formatOptions: CurrencyFormatOptions = { currencyCode, minimumFractionDigits: 0, symbolSpace: false };
  const remainingPrice = minOrderItemsPrice - cartPrice;
  const minOrderPrice = currencyFormat(remainingPrice, formatOptions);
  return translateFn('min_order_price_is_needed_to_obtain_the_offer', {
    _MIN_ORDER_PRICE: minOrderPrice
  });
}

function decorateOfferWithInactiveText(
  offer: Offer,
  cart: Cart,
  translateFn: GetTextByKeyType,
  currencyCode: CurrencyCode
): Offer {
  const inactiveText = getCartOfferMinOrderItemsPriceText(offer, cart.itemsFinalPrice, translateFn, currencyCode);
  if (!inactiveText) return offer;
  return { ...offer, inactiveText };
}

function getCartInactiveOffers(cart: Cart): Offer[] {
  if (!cartHasInactiveOffers(cart)) return [];
  return cart.offers.filter((offer) => offer.minOrderItemsPrice > cart.itemsFinalPrice);
}

function getCartInactiveOffersMessage(inactiveOffers: Offer[], translateFn: GetTextByKeyType): string {
  if (!inactiveOffers.length) return;
  if (inactiveOffers.length === 1) {
    const inactiveOfferName = `"${inactiveOffers[0].name}"`;
    return translateFn('you_will_continue_your_order_without_the_offer', {
      _OFFER_NAME: inactiveOfferName
    });
  }
  const inactiveOfferNames = inactiveOffers.map((offer) => `"${offer.name}"`).join(', ');
  return translateFn('you_will_continue_your_order_without_the_offers', {
    _OFFERS_NAME: inactiveOfferNames
  });
}
