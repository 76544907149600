import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RewardsBalanceBadgeService } from './rewards-balance-badge.service';
import { RewardsBalanceBadgeState } from './rewards-balance-badge.types';
import { User } from '@box-types';
import { CouponsService, PointsAnimationService, UserService } from '@box-core/services';
import { isPrerenderBrowser } from '@box/utils';
import { OverlayRef } from '@angular/cdk/overlay';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Component({
  selector: 'rewards-balance-badge',
  templateUrl: './rewards-balance-badge.component.html',
  styleUrls: ['./rewards-balance-badge.component.scss'],
  providers: [RewardsBalanceBadgeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardsBalanceBadgeComponent implements OnInit, OnDestroy {
  public state: RewardsBalanceBadgeState;
  public pointsText: string;
  public currencyText: string;
  public isTutorialOpen: boolean;
  public couponsNumber: number;

  private couponsSubscription: Subscription;
  private stateSubscription: Subscription;
  private userSubscription: Subscription;
  private rulerSubscription: Subscription;
  private overlayCtaSubscription: Subscription;
  private overlayCloseSubscription: Subscription;
  private rewardsOverlayRef: OverlayRef;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private rewardsBalanceBadgeService: RewardsBalanceBadgeService,
    private pointsAnimationService: PointsAnimationService,
    private globalStateService: GlobalStateService,
    private userService: UserService
  ) {}

  @HostBinding('class') public hostClass = 'rewards-balance-badge';
  @HostBinding('class.rewards-balance-badge-expanded') public expanded: boolean;

  ngOnInit(): void {
    this.setStateSubscription();
    this.setUserSubscription();
    this.setCouponsSubscription();
    this.initializeStateInterval();
  }

  ngOnDestroy(): void {
    this.stateSubscription?.unsubscribe();
    this.couponsSubscription?.unsubscribe();
    this.pointsAnimationService.stopStateInterval();
    this.userSubscription?.unsubscribe();
    this.overlayCtaSubscription?.unsubscribe();
    this.overlayCloseSubscription?.unsubscribe();
    this.closeOverlay();
  }

  private setStateSubscription(): void {
    this.stateSubscription = this.pointsAnimationService.state$.subscribe((state) => {
      this.state = state;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setUserSubscription(): void {
    this.userSubscription = this.globalStateService.getUser$().subscribe((user: User) => {
      this.pointsText = this.pointsAnimationService.getUserPointsBalanceText(user);
      this.currencyText = this.pointsAnimationService.getUserCurrencyBalanceText(user);
      if (this.rewardsBalanceBadgeService.shouldShowTutorial()) this.isTutorialOpen = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  private initializeStateInterval(): void {
    if (isPrerenderBrowser(window)) return;
    this.pointsAnimationService.startStateInterval();
  }

  private setCouponsSubscription(): void {
    this.couponsSubscription = this.globalStateService.decoratedCoupons$.subscribe((coupons) => {
      this.expanded = Boolean(coupons?.length);
      this.couponsNumber = coupons?.length;
      this.changeDetectorRef.detectChanges();
    });
  }

  public onClickPoints(): void {
    this.rewardsBalanceBadgeService.navigateToRewards();
    this.closeOverlay();
  }

  public onClickCoupon(): void {
    this.rewardsBalanceBadgeService.navigateToCoupons();
    this.closeOverlay();
  }

  public onClickCta(): void {
    this.rewardsBalanceBadgeService.navigateToRewards();
    this.rewardsBalanceBadgeService.updateUserEventHistory();
    this.closeOverlay();
  }

  public onClickClose(): void {
    this.rewardsBalanceBadgeService.updateUserEventHistory();
    this.closeOverlay();
  }

  public closeOverlay(): void {
    this.rewardsOverlayRef?.dispose();
    this.rewardsOverlayRef?.detach();
    this.rulerSubscription?.unsubscribe();
    this.isTutorialOpen = false;
    this.changeDetectorRef.detectChanges();
  }
}
