import { Injectable } from '@angular/core';
import {
  ConfirmDialogResponse,
  InfoDialogData,
  Product,
  ProductInstance,
  ShopSuggestionBanner,
  Offer,
  OfferInstance,
  CartItemMyoDialogResponse
} from '@box-types';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {
  OfferWizardDialogComponent,
  ProductMYODialogComponent,
  SameMYODialogComponent,
  ShopSuggestionDialogComponent,
  UnavailableItemsDialogComponent
} from '@box-delivery/components';
import { AnalyticsService, CartService, DialogService, ShopService } from '@box-core/services';
import { Location } from '@angular/common';
import { BoxConfirmDialogComponent, BoxInfoDialogComponent } from '@box-shared/components';
import {
  SameMYODialogResponse,
  SameMYODialogData
} from '@box-delivery/components/same-myo-dialog/same-myo-dialog.types';
import { createOfferInstance, createProductInstance, getCartOfferGAConfig, getCartProductGAConfig } from '@box/utils';
import { Observable, switchMap, of } from 'rxjs';
import { CartOfferService } from '@box-core/services/cart-item.service';

@Injectable()
export class ShopMenuDialogService {
  constructor(
    private dialogService: DialogService,
    private location: Location,
    private shopService: ShopService,
    private cartService: CartService,
    private analyticsService: AnalyticsService
  ) {}

  public openSameMYODialog$<I, N>(
    dialogData: SameMYODialogData<I, N>
  ): Observable<CartItemMyoDialogResponse<I, N> | null> {
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'box-dialog',
      data: dialogData
    };

    return this.dialogService
      .openDialog(SameMYODialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        switchMap((data: SameMYODialogResponse) => {
          if (!data?.openMYO) return of(null);
          const itemType = dialogData.cartItemService.getItemType();
          if (itemType === 'offer') return this.openOfferWizard(dialogData.item as unknown as Offer).afterClosed();
          if (itemType === 'product') return this.openProductMYO(dialogData.item as unknown as Product).afterClosed();
        })
      );
  }

  public openProductMYO(product: Product): MatDialogRef<ProductMYODialogComponent> {
    const shop = this.shopService.getShop();
    const productInstance = createProductInstance(product);
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'box-dialog',
      data: {
        product,
        productInstance: productInstance,
        shop: shop
      }
    };

    this.location.replaceState(`/delivery/${shop.locationKey}/${shop.vanity_url}`, `productId=${product._id}`);
    const dialogRef = this.dialogService.openDialog(ProductMYODialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(() => this.triggerAnalyticsProductEvent(product, productInstance));
    dialogRef
      .afterClosed()
      .subscribe(() => this.location.replaceState(`/delivery/${shop.locationKey}/${shop.vanity_url}`));
    return dialogRef;
  }

  public openOfferWizard(offer: Offer): MatDialogRef<OfferWizardDialogComponent> {
    const shop = this.shopService.getShop();
    const offerInstance = createOfferInstance(offer);
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'box-dialog',
      data: { offer, offerInstance: offerInstance, shop: shop }
    };

    this.location.replaceState(`/delivery/${shop.locationKey}/${shop.vanity_url}`, `offerId=${offer._id}`);
    const dialogRef = this.dialogService.openDialog(OfferWizardDialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(() => this.triggerAnalyticsOfferEvent(offer, offerInstance));
    dialogRef
      .afterClosed()
      .subscribe(() => this.location.replaceState(`/delivery/${shop.locationKey}/${shop.vanity_url}`));
    return dialogRef;
  }

  public openDFYWarningDialog(offer: Offer, cartOfferService: CartOfferService): void {
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      restoreFocus: false,
      data: {
        messages: ['you_can_only_add_one_dfy_offer_per_order'],
        confirmText: 'ok_',
        cancelText: 'cancel_'
      }
    };
    this.dialogService
      .openDialog(BoxConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (!data?.accepted) return;
        const cartOffer = cartOfferService.getCartItem(offer);
        const instance = cartOffer.cartInstances[0];
        cartOfferService.removeItem(offer, instance);
      });
  }

  public openSuggestionDialog(suggestionBanner: ShopSuggestionBanner): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog-fit-content',
      restoreFocus: false,
      autoFocus: false,
      data: { suggestionBanner }
    };
    this.dialogService.openDialog(ShopSuggestionDialogComponent, dialogConfig);
  }

  public showProductInfoDialog(data: InfoDialogData): void {
    const dialogConfig: MatDialogConfig = { restoreFocus: false, autoFocus: false, data };
    this.dialogService.openDialog(BoxInfoDialogComponent, dialogConfig);
  }

  public showUnavailableItemsDialog(products: Product[]): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog-fit-content',
      data: { title: 'unavailable_products', products: products }
    };
    this.dialogService.openDialog(UnavailableItemsDialogComponent, dialogConfig);
  }

  private triggerAnalyticsProductEvent(product: Product, productInstance: ProductInstance): void {
    const shop = this.shopService.getShop();
    const categoryName = product.category.name ?? '';
    const gaConfig = getCartProductGAConfig(product, productInstance, shop, categoryName);
    this.analyticsService.addGAEcommerceEvent('view_item', gaConfig);
  }

  private triggerAnalyticsOfferEvent(offer: Offer, offerInstance: OfferInstance): void {
    const shop = this.shopService.getShop();
    const gaConfig = getCartOfferGAConfig(offer, offerInstance, shop, 'Offers');
    this.analyticsService.addGAEcommerceEvent('view_item', gaConfig);
  }
}
