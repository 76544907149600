import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShopService } from '@box-core/services';
import { Shop, CartItemMyoDialogResponse, Product, ProductInstance } from '@box-types';
import { ProductMYOOptions } from '../product-myo/product-myo.types';
import { ProductMYODialogData } from './product-myo-dialog.types';

@Component({
  selector: 'product-myo-dialog',
  templateUrl: './product-myo-dialog.component.html',
  styleUrls: ['./product-myo-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMYODialogComponent implements OnInit {
  public productMYOOptions: ProductMYOOptions;
  private shop: Shop;

  constructor(
    private shopService: ShopService,
    private dialogRef: MatDialogRef<ProductMYODialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ProductMYODialogData
  ) {}

  ngOnInit(): void {
    this.shop = this.data.shop;
    this.productMYOOptions = {
      editMode: this.data.editMode,
      product: this.data.product,
      productInstance: this.data.productInstance,
      productInstanceToEdit: this.data.productInstanceToEdit,
      canClose: true,
      isSuperMarket: this.shop.isSuperMarket,
      showQuantity: true,
      commentsAllowed: this.shop.isProductCommentsAllowed,
      customPriceCheck: true
    };
  }

  public closeDialog(response?: CartItemMyoDialogResponse<Product, ProductInstance>) {
    this.dialogRef.close(response);
  }

  public onSubmit(data: ProductMYOOptions) {
    const { editMode, product, productInstance, productInstanceToEdit } = data;

    const responseData: CartItemMyoDialogResponse<Product, ProductInstance> = {
      editMode,
      item: product,
      itemInstance: productInstance,
      itemInstanceToEdit: productInstanceToEdit
    };
    this.closeDialog(responseData);
  }
}
