import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from '@box-env/environment';
import { Order, OrderCreateOptions, APIResponse, APIError } from '@box-types';
import { getOrderDeliveryStatus, isOrderAccepted, isOrderValid, ObservableCacheDecorator } from '@box/utils';
import { unionBy, orderBy } from 'lodash-es';
import { UserService } from '@box-core/services/user.service';
import { SentryService } from '@box-core/services/sentry.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

const invalidationSignalOrderHistorySource = new Subject<null>();
const invalidationSignalOrderHistory$: Observable<string> = invalidationSignalOrderHistorySource.asObservable();
const ORDER_HISTORY_SESSION_EXPIRATION = 5 * 60 * 1000; // 5 minutes

@Injectable({ providedIn: 'root' })
export class OrdersService {
  private BOX_API: string = environment.application.API_URL;
  private ORDERS_API_URL: string = environment.application.ORDERS_API_URL;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private sentryService: SentryService,
    private globalStateService: GlobalStateService
  ) {}

  public triggerOrderHistoryCacheInvalidation(): void {
    // providing a null value clears all the cache entries for every key
    invalidationSignalOrderHistorySource.next(null);
  }
  /** Fetch order is using the orders/find endpoint to get the details of one order. If that
   * request returns an empty endpoint, we need to handle it as an error in the client side.
   * For that reason, we throw a client side error. */
  public fetchOrder(friendlyId: string): Observable<Order> {
    return this.fetchOrders([friendlyId]).pipe(
      map((orders) => {
        if (orders?.length) return orders[0];
        throw new Error('Fetch Order Error');
      })
    );
  }

  public fetchOrders(friendlyIds: string[]): Observable<Order[]> {
    const params = new HttpParams().set('friendlyIds', friendlyIds.join(','));
    return this.http
      .get(`${this.BOX_API}/orders/find`, { params })
      .pipe(map((response: APIResponse<{ orders: Order[] }>) => response.payload.orders));
  }

  public updateOrderHistory(orders: Order[]): void {
    const currentOrderHistory = this.globalStateService.getOrderHistory();
    const updatedOrderHistory = unionBy(orders, currentOrderHistory, 'friendlyId');
    const acceptedOrderHistory = updatedOrderHistory.filter((order) => isOrderAccepted(order));
    const sortedOrderHistory = orderBy(acceptedOrderHistory, 'createdAt', 'desc');
    this.globalStateService.setOrderHistory(sortedOrderHistory);
  }

  public updateOrderHistoryAfterRate(order: Order, rating: number): void {
    const currentOrders = this.globalStateService.getOrderHistory();
    const currentOrder = currentOrders.find((currentOrder) => currentOrder.friendlyId === order.friendlyId);
    if (!currentOrder) return;
    currentOrder.rating = rating;
    this.updateOrderHistory(currentOrders);
  }

  public getOrderHistory$(): Observable<Order[]> {
    if (this.userService.isGuest) return of([] as Order[]);
    return this.fetchOrderHistory$();
  }

  @ObservableCacheDecorator({
    expirationTimeInMs: ORDER_HISTORY_SESSION_EXPIRATION,
    invalidateSignal$: invalidationSignalOrderHistory$
  })
  public fetchOrderHistory$(): Observable<Order[]> {
    return this.http.get(`${this.BOX_API}/orders/get/userlist`).pipe(
      map((response: APIResponse<{ orders: Order[] }>) => {
        const orders = response.payload.orders ?? [];
        return orders.filter((order) => isOrderValid(order));
      }),
      tap((orders) => {
        this.globalStateService.setOrderHistory(orders);
      }),
      catchError((error: APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Orders',
          domainDetails: 'Fetch Orders',
          severity: 'warning'
        });
        return of([] as Order[]);
      })
    );
  }

  public createOrder(options: OrderCreateOptions): Observable<Order> {
    const headers = new HttpHeaders({ 'Accept-Version': '2' });
    return this.http
      .post(`${this.ORDERS_API_URL}/v2/orders`, options, { headers })
      .pipe(map((response: APIResponse<{ order: Order }>) => response.payload.order));
  }

  public cancelOrder(orderID: string): Observable<Order> {
    return this.http
      .put(`${this.BOX_API}/orders/failed/${orderID}`, {})
      .pipe(map((response: APIResponse<{ order: Order }>) => response.payload.order));
  }

  public updateOrderStatus(merchantRef: string): Observable<Order> {
    return this.http
      .post(`${this.ORDERS_API_URL}/orders/updateStatus`, { merchantRef })
      .pipe(map((response: APIResponse<{ order: Order }>) => response.payload.order));
  }

  public fetchDaasOrderReceipt(friendlyId: string): Observable<string> {
    return this.http
      .get(`${this.BOX_API}/orders/${friendlyId}/delivery-fee-receipt`)
      .pipe(map((response: APIResponse<{ link: string }>) => response.payload.link));
  }

  public orderDecorator(order: Order): Order {
    return { ...order, status: getOrderDeliveryStatus(order) };
  }
}
