import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PromoCampaignsService, CartService, ShopService } from '@box-core/services';
import {
  Product,
  PromoCampaign,
  ShopSuggestionBanner,
  SuggestionDialogBanner,
  Offer,
  ProductInstance,
  OfferInstance,
  Cart,
  CartItemsServiceConfiguration
} from '@box-types';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ShopSuggestionDialogService } from './shop-suggestion-dialog.service';
import { ShopMenuPageService } from '../../services';
import { getSuggestionDialogBanner } from '@box/utils';
import { CartProductService, CartOfferService } from '@box-core/services/cart-item.service';

@Component({
  selector: 'shop-suggestion-dialog',
  templateUrl: './shop-suggestion-dialog.component.html',
  styleUrls: ['./shop-suggestion-dialog.component.scss'],
  providers: [ShopSuggestionDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopSuggestionDialogComponent
  extends BoxDialogWrapperComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public products: Product[];
  public offers: Offer[];
  public suggestionDialogBanner: SuggestionDialogBanner;
  private itemsSubscription: Subscription;
  private campaign: PromoCampaign;
  private cartSubject: BehaviorSubject<Cart>;

  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<ShopSuggestionDialogComponent>,
    private promoCampaignsService: PromoCampaignsService,
    private shopSuggestionDialogService: ShopSuggestionDialogService,
    private shopMenuPageService: ShopMenuPageService,
    private changeDetectorRef: ChangeDetectorRef,
    private shopService: ShopService,
    private cartService: CartService,
    private cartProductService: CartProductService,
    private cartOfferService: CartOfferService,
    @Inject(MAT_DIALOG_DATA) private data: { suggestionBanner: ShopSuggestionBanner }
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.setCartItemsServiceConfig();
    this.setDialogData(this.data);
    this.setItemsSubscription();
  }

  private setCartItemsServiceConfig(): void {
    const cartItemsServiceConfig: CartItemsServiceConfiguration = {
      cartSource: this.shopSuggestionDialogService.cartSource,
      saveCartToStorage: false
    };
    this.cartProductService.setCartConfiguration(cartItemsServiceConfig);
    this.cartOfferService.setCartConfiguration(cartItemsServiceConfig);
  }

  override ngAfterViewInit(): void {
    this.changeDetectorRef.detach();
  }

  private setDefaultCartItemsServiceConfig(): void {
    const cartItemsServiceConfig: CartItemsServiceConfiguration = {
      cartSource: this.cartService.cartSource,
      saveCartToStorage: true
    };
    this.cartProductService.setCartConfiguration(cartItemsServiceConfig);
    this.cartOfferService.setCartConfiguration(cartItemsServiceConfig);
  }

  ngOnDestroy(): void {
    this.itemsSubscription?.unsubscribe();
    this.shopSuggestionDialogService.clearCart();
    this.setDefaultCartItemsServiceConfig();
  }

  public onProductClick(index: number, product: Product): void {
    this.shopMenuPageService.openProductMyo$({ item: product, itemsType: 'regular', index });
  }

  public onProductAdd(product: Product): void {
    this.shopMenuPageService.onProductAdd$({ item: product, itemsType: 'regular' });
  }

  public onProductRemove(product: Product): void {
    this.shopMenuPageService.onProductRemove$({ item: product, itemsType: 'regular' });
  }

  public onOfferAdd(item: Product | Offer): void {
    if (!(item as Offer).groups) {
      this.shopMenuPageService.onProductAdd$({
        item: item as Product,
        itemsType: 'regular'
      });
      return;
    }
    this.shopMenuPageService.openOfferMyo$({ item: item as Offer, itemsType: 'regular' });
  }

  public onOfferRemove(item: Product | Offer): void {
    if (!(item as Offer).groups) {
      this.shopMenuPageService.onProductRemove$({
        item: item as Product,
        itemsType: 'regular'
      });
      return;
    }
    this.shopMenuPageService.onOfferRemove$({ item: item as Offer, itemsType: 'regular' });
  }

  public onOfferClick(index: number, item: Offer | Product): void {
    if (!(item as Offer).groups) {
      this.shopMenuPageService.openProductMyo$({
        item: item as Product,
        itemsType: 'regular',
        index
      });
      return;
    }
    this.shopMenuPageService.openOfferMyo$({ item: item as Offer, itemsType: 'regular', index });
  }

  public closeDialog(): void {
    this.dialogRef.close();
    this.shopSuggestionDialogService.clearCart();
  }

  public onSubmit(): void {
    const cart = this.cartSubject.getValue();
    this.cartService.addItemsToMainCart<Product, ProductInstance>(cart.products, this.cartProductService);
    this.cartService.addItemsToMainCart<Offer, OfferInstance>(cart.offers, this.cartOfferService);
    this.closeDialog();
  }

  private setDialogData(data: { suggestionBanner: ShopSuggestionBanner }): void {
    const { campaignName } = data.suggestionBanner;
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    this.campaign = promoCampaigns.find((c) => c.name === campaignName);
    this.suggestionDialogBanner = getSuggestionDialogBanner(this.campaign);
    this.products = this.shopService.resetProductsQuantity(data.suggestionBanner.products);
    this.offers = this.shopService.resetOffersQuantity(data.suggestionBanner.offers);
    this.cartSubject = this.shopSuggestionDialogService.cartSource;
  }

  private setItemsSubscription(): void {
    this.itemsSubscription = this.shopSuggestionDialogService.cart$.subscribe(() => {
      const { products: bannerProducts, offers: bannerOffers } = this.data.suggestionBanner;
      this.offers = bannerOffers.map((offer) =>
        this.shopSuggestionDialogService.syncOfferCartQuantityWithCartState(offer)
      );
      this.products = bannerProducts.map((product) =>
        this.shopSuggestionDialogService.syncProductCartQuantityWithCartState(product)
      );
      this.changeDetectorRef.detectChanges();
    });
  }

  public trackById(index: number, item: Product | Offer): string {
    return item._id;
  }
}
