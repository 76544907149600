import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of, Subject } from 'rxjs';
import { FAQData, FAQCategory, APIResponse } from '@box-types';
import { map, tap, catchError } from 'rxjs/operators';
import { ObservableCacheDecorator } from '@box/utils';
import { ConfigurationService } from '@box-core/services/configuration.service';
import { SentryService } from '@box-core/services/sentry.service';

const invalidationFAQsSource = new Subject<null>();
const invalidationFAQS$: Observable<string> = invalidationFAQsSource.asObservable();
const FAQ_SESSION_EXPIRATION = 5 * 60 * 1000; // 5 hours

@Injectable({ providedIn: 'root' })
export class FAQService {
  public faq: BehaviorSubject<FAQCategory[]> = new BehaviorSubject<FAQCategory[]>([]);

  constructor(
    private http: HttpClient,
    private configurationService: ConfigurationService,
    private sentryService: SentryService
  ) {}

  public triggerFAQsCacheInvalidation(): void {
    // providing a null value clears all the cache entries for every key
    invalidationFAQsSource.next(null);
  }

  @ObservableCacheDecorator({
    expirationTimeInMs: FAQ_SESSION_EXPIRATION,
    invalidateSignal$: invalidationFAQS$
  })
  public fetchFAQ$(): Observable<FAQCategory[]> {
    // Cache-Control: no-cache, no-store, must-revalidate, post-check=0, pre-check=0
    // Expires: 0
    const faqUrl = this.getFaqUrl();
    if (!faqUrl) return of([]);
    return this.http.get<APIResponse<FAQData>>(faqUrl).pipe(
      map((response: APIResponse<FAQData>) => response.payload),
      map((response) => this.filterWebFAQs(response.faqs)),
      tap((faq) => this.setFAQ(faq)),
      catchError((error) => {
        this.sentryService.captureException(error, {
          domain: 'FAQ',
          severity: 'warning'
        });
        return [];
      })
    );
  }

  private getFaqUrl(): string {
    const faqs = this.configurationService.getConfiguration().faqs;
    return faqs as string;
  }

  private setFAQ(faq: FAQCategory[]): void {
    this.faq.next(faq);
  }

  public filterWebFAQs(faq: FAQCategory[]): FAQCategory[] {
    return faq.map((category) => ({
      title: category.title,
      dynamicDescriptions: (category.dynamicDescriptions ?? []).filter(
        (d) => !d.platforms || d.platforms.includes('web')
      ),
      questions: (category.questions ?? []).filter((q) => !q.platforms || q.platforms.includes('web'))
    }));
  }
}
