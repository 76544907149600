<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header
      class="shop-suggestion-dialog-header"
      title="add_products_and_earn"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main
    #dialogBody
    bodyScroll
    class="box-dialog-content shop-suggestion-dialog-content"
    (bodyScroll)="onBodyScroll($event)"
  >
    <div class="shop-suggestion-dialog-banner-wrapper">
      <suggestion-dialog-banner
        [suggestionDialogBanner]="suggestionDialogBanner"
        class="shop-suggestion-dialog-banner"
      ></suggestion-dialog-banner>
    </div>
    <div class="shop-suggestion-dialog-products-wrapper">
      <food-offer
        class="hoverable"
        *ngFor="let offer of offers; trackBy: trackById; let i = index"
        [offer]="offer"
        (add)="onOfferAdd($event)"
        (remove)="onOfferRemove($event)"
        (click)="onOfferClick(i, offer)"
      ></food-offer>
      <food-product
        class="hoverable"
        *ngFor="let product of products; trackBy: trackById; let i = index"
        [product]="product"
        (add)="onProductAdd($event)"
        (remove)="onProductRemove($event)"
        (view)="onProductClick(i, product)"
      ></food-product>
    </div>
  </main>
  <div class="shop-suggestion-dialog-cta">
    <button box-button theme="primary" (click)="onSubmit()">{{ 'add_to_cart' | translate }}</button>
  </div>
</div>
