import { Injectable } from '@angular/core';
import { Payment, PaymentTypesOptions, DeliveryMethod } from '@box-types';
import { BehaviorSubject } from 'rxjs';
import {
  ConfigurationService,
  LoyaltyService,
  PrivacyConsentService,
  PromoCampaignsService,
  ShopService,
  UserService,
  CryptoService,
  translate
} from '@box-core/services';
import {
  cardToPaymentType,
  getStoredPaymentHash,
  STORAGE_PAYMENT_KEY,
  getCampaignsCompatibleWithPaymentType,
  storageRemove,
  storageSet,
  isStorageSupported,
  isPaymentCard
} from '@box/utils';
import { GlobalStateService } from '@box-core/services/global-state.service';

export const CASH_TYPE: Payment = { icon: 'cash', title: 'cash_', type: 'cash' };
export const CASH_OR_POS_TYPE: Payment = { icon: 'cash', title: 'cash_or_pos_upon_receipt', type: 'cash' };

export const EMPTY_TYPE: Payment = {
  icon: 'cash',
  title: 'payment_method_choice',
  type: 'empty',
  firstOrderCardPoints: 0,
  paymentCampaigns: []
};
export const CARD_IN_NEXT_STEP_SAVE_TYPE: Payment = {
  icon: 'card',
  alternativeIcon: 'card',
  title: 'add_new_card_with_save_in_next_step',
  type: 'card',
  addNew: true,
  saveCard: true,
  firstOrderCardPoints: 0,
  paymentCampaigns: []
};
export const CARD_IN_NEXT_STEP_NOSAVE_TYPE: Payment = {
  icon: 'card',
  alternativeIcon: 'card',
  title: 'add_new_card_without_save_in_next_step',
  type: 'card',
  addNew: true,
  saveCard: false,
  firstOrderCardPoints: 0,
  paymentCampaigns: []
};

@Injectable({ providedIn: 'root' })
export class PaymentTypesService {
  private readonly paymentSource = new BehaviorSubject<Payment>(undefined);
  public payment$ = this.paymentSource.asObservable();

  private readonly paymentsSource = new BehaviorSubject<Payment[]>([]);
  public payments$ = this.paymentsSource.asObservable();

  constructor(
    private privacyConsentService: PrivacyConsentService,
    private loyaltyService: LoyaltyService,
    private promoCampaignsService: PromoCampaignsService,
    private shopService: ShopService,
    private configService: ConfigurationService,
    private cryptoService: CryptoService,
    private globalStateService: GlobalStateService
  ) {}

  public setPaymentType(paymentType: Payment): void {
    this.setLocalPaymentType(paymentType);
    this.paymentSource.next(paymentType);
  }

  public getPaymentType(): Payment {
    return this.paymentSource.getValue();
  }

  public getEmptyType() {
    return EMPTY_TYPE;
  }

  public clearPaymentType(): void {
    storageRemove(STORAGE_PAYMENT_KEY, window.localStorage);
    this.paymentSource.next(undefined);
  }

  public setPaymentTypes(paymentTypes: Payment[]): void {
    this.paymentsSource.next(paymentTypes);
  }

  public getPaymentTypes(): Payment[] {
    return this.paymentsSource.getValue();
  }

  public clearPaymentTypes(): void {
    this.paymentsSource.next([]);
  }

  public initializePaymentTypes(options: PaymentTypesOptions): void {
    const paymentTypes = this.generatePaymentTypes(options);
    this.setPaymentTypes(paymentTypes.map((t) => this.decoratePaymentType(t)));
  }

  public setDefaultPaymentType(): void {
    const user = this.globalStateService.getUser();
    if (!user.hasOrdered) return this.setPaymentType(EMPTY_TYPE);
    const paymentTypes = this.paymentsSource.getValue();
    const storedPaymentHash = getStoredPaymentHash();
    if (!paymentTypes?.length || !storedPaymentHash) return this.setPaymentType(EMPTY_TYPE);
    const defaultPaymentType =
      paymentTypes.find((paymentType) => {
        const paymentTypeStoreModel = { type: paymentType.type, pan: paymentType?.card?.pan };
        const paymentTypeHash = this.cryptoService.hash(paymentTypeStoreModel);
        return paymentTypeHash === storedPaymentHash;
      }) ?? EMPTY_TYPE;
    return this.setPaymentType(defaultPaymentType);
  }

  public setFirstAvailableCardPayment(): void {
    const payments = this.paymentsSource.getValue();
    const cardPayment = payments.find((payment) => payment.type === 'card');
    if (cardPayment) this.paymentSource.next(cardPayment);
  }

  public hasCashPaymentMethod(shopSupportCashDelivery: boolean, deliveryMethod: DeliveryMethod): boolean {
    if (deliveryMethod === 'delivery') return shopSupportCashDelivery;
    const enableCashForTakeAway = this.configService.get('enableCashForTakeAway', false);
    if (deliveryMethod === 'takeAway') return enableCashForTakeAway;
  }

  public getCardPayment(): Payment {
    const payments = this.getPaymentTypes();
    return payments.find((payment) => isPaymentCard(payment));
  }

  public isCurrentPaymentCard(): boolean {
    const currentPayment = this.getPaymentType();
    return isPaymentCard(currentPayment);
  }

  private generatePaymentTypes(options: PaymentTypesOptions): Payment[] {
    const paymentTypes: Payment[] = [];
    if (this.hasCashPaymentMethod(options.shop.cash, options.deliveryMethod)) {
      const cashType = options.shop.hasPosPaymentMethod ? CASH_OR_POS_TYPE : CASH_TYPE;
      paymentTypes.push(cashType);
    }
    if (options.shop.card && options.cards?.length) {
      paymentTypes.push(...options.cards.map((card) => cardToPaymentType(card, translate)));
    }
    if (options.shop.card) paymentTypes.push(CARD_IN_NEXT_STEP_SAVE_TYPE, CARD_IN_NEXT_STEP_NOSAVE_TYPE);
    if (paymentTypes.length === 0) paymentTypes.push(EMPTY_TYPE);
    return paymentTypes;
  }

  private setLocalPaymentType(paymentType: Payment): void {
    const uxConsent = this.privacyConsentService.getPrivacyConsent()?.ux;
    if (!uxConsent) return;

    if (!isStorageSupported()) return;
    if (paymentType.addNew) return storageRemove(STORAGE_PAYMENT_KEY, window.localStorage);

    const paymentToStore = { type: paymentType.type, pan: paymentType?.card?.pan } as { pan: string; type: string };
    const paymentHash = this.cryptoService.hash(paymentToStore);
    storageSet<string>(STORAGE_PAYMENT_KEY, paymentHash, window.localStorage);
  }

  private decoratePaymentType(paymentType: Payment): Payment {
    const businessVertical = this.shopService.getShop()?.businessVertical;
    const paymentCampaigns = this.promoCampaignsService.getPaymentCampaigns(businessVertical);
    const isCash: boolean = paymentType.type === 'cash';
    const isDisabled: boolean = paymentType.disabled;
    if (isCash || isDisabled) return { ...paymentType };
    const firstOrderCardPoints = this.getFirstOrderCardCampaignPoints();
    const compatiblePaymentCampaigns = getCampaignsCompatibleWithPaymentType(paymentType, paymentCampaigns);
    return { ...paymentType, firstOrderCardPoints, paymentCampaigns: compatiblePaymentCampaigns };
  }

  private getFirstOrderCardCampaignPoints(): number {
    const user = this.globalStateService.getUser();
    const firstOrderCardEligible = !user.hasPayedUsingCard;
    if (!firstOrderCardEligible) return 0;
    return this.loyaltyService.getClaimablePoints('first_order_card');
  }
}
