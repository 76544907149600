import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { OrdersService, UserService, SentryService, DialogService } from '@box-core/services';
import { Order, APIError } from '@box-types';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { removeCartCollectionFromStorage } from '@box/utils';
import { GlobalStateService } from '@box-core/services/global-state.service';

const bankErrorDialogData = {
  title: 'payment_failure',
  messages: ['the_bank_could_not_process_the_payment_request']
};

@Injectable({ providedIn: 'root' })
export class PaymentResolver implements Resolve<Order | void> {
  constructor(
    private router: Router,
    private userService: UserService,
    private sentryService: SentryService,
    private ordersService: OrdersService,
    private dialogService: DialogService,
    private globalStateService: GlobalStateService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Order> | void {
    const merchantRef = route.queryParams.merchantRef as string;
    if (!merchantRef) {
      const error = new Error('Payment Gateway Params Missing');
      this.sentryService.captureException(error, {
        domain: 'Payments',
        domainDetails: 'Payment Resolver',
        severity: 'warning'
      });
      this.dialogService.openErrorDialog(error);
      void this.router.navigate(['/home']);
      return;
    }

    return this.ordersService.updateOrderStatus(merchantRef).pipe(
      tap((order) => {
        /*
         * WARNING if you set the order here in the orders service
         * then after visiting the checkout page
         * you will be automatically redirected to the order-status page
         * since handleOrderResponse will pick up a completed order
         */
        this.handleUpdateStatusResponse(order);
      }),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Payments',
          domainDetails: 'Payment Resolver',
          severity: 'warning'
        });
        this.dialogService.openErrorDialog(error);
        this.router.navigate(['/home']);
        return of(null);
      })
    );
  }

  private handleUpdateStatusResponse(order: Order): void {
    const orderStatus = order.shopResponse.status;
    const orderStatusOK = ['PENDING', 'ACCEPTED', 'COMPLETED'].includes(orderStatus);
    if (orderStatusOK) return this.handleSuccessfulPayment(order);
    return this.handleFailedPayment(order);
  }

  private handleSuccessfulPayment(order: Order): void {
    const collectionType = order.shop?.collectionType;
    removeCartCollectionFromStorage(collectionType, window.localStorage);
    void this.router.navigate(['/checkout', 'order-status'], { queryParams: { friendlyId: order.friendlyId } });
  }

  private handleFailedPayment(order: Order): void {
    /* we set the order here since
     * we are redirecting to the checkout page
     * and we need to have the available data there
     */
    this.globalStateService.setOrder(order);
    if (order?.marketPlacePoints?.consumed) this.userService.setPointsBeforeConsumption(order._id);
    this.dialogService.openInfoDialog(bankErrorDialogData, {
      closeOnNavigation: false
    });
    this.router.navigate(['/checkout'], { queryParams: { vanityUrl: order.shop.vanity_url } });
  }
}
