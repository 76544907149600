import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import {
  Shop,
  APIError,
  BoxDialogValidationMode,
  BoxOtpVerificationReason,
  SMLoyaltyConfiguration,
  MarketCardConnectOptions,
  MarketCardPasswordResetOptions,
  MarketVerifyOTPOptions,
  MarketCardValidationConfig,
  MarketCardValidationDialogData,
  MarketCardValidationDialogResponse,
  MarketOTPOptions,
  GetTextByKeyType
} from '@box-types';
import { ConfigurationService, MarketLoyaltyService, LoaderService, DialogService } from '@box-core/services';
import { finalize } from 'rxjs/operators';
import { generateImageSrc } from '@box/utils';
import { LanguageService } from '@box-core/services/language.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Component({
  selector: 'market-card-validation-dialog',
  templateUrl: './market-card-validation-dialog.component.html',
  styleUrls: ['./market-card-validation-dialog.component.scss']
})
export class MarketCardValidationDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public logo: string;
  public logoAlt: string;
  public shopName: string;
  public shopNumber: string;
  public phoneNumber: string;
  public email: string;
  public maskedPhoneNumber: string;
  public maskedEmail: string;

  public mode: BoxDialogValidationMode;
  public resendText: string;
  public resendButtonText: string;
  public validationButtonDisabled: boolean;
  public validationFormControl: FormControl;
  public readonly t: GetTextByKeyType; // for the template to use

  private shop: Shop;
  private saveCard: boolean;
  private reason: BoxOtpVerificationReason;
  private otpId: string;
  private cardNumber: string;
  private integratorName: string;
  private integratorOptions: SMLoyaltyConfiguration;
  private validationConfig: MarketCardValidationConfig;
  private verificationToken: string;

  constructor(
    public override renderer: Renderer2,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<MarketCardValidationDialogComponent>,
    private loaderService: LoaderService,
    private marketLoyaltyService: MarketLoyaltyService,
    private configService: ConfigurationService,
    private languageService: LanguageService,
    private globalStateService: GlobalStateService,
    @Inject(MAT_DIALOG_DATA)
    private data: MarketCardValidationDialogData
  ) {
    super(renderer);
    // to avoid using 'this.' within the component's code
    this.t = this.languageService.getTextByKey.bind(this.languageService);
    this.shop = this.data.shop;
    this.mode = this.shop.loyaltyCardLoginMethod;
    this.validationConfig = this.data.validationConfig;
    this.saveCard = this.data.saveCard ?? false;
    this.reason = this.data.reason;
  }

  ngOnInit(): void {
    this.setDialogContent();
    this.setValidationFormControl();
    this.requestPinOtp();
  }

  private requestPinOtp(): void {
    if (this.mode === 'OTP' || this.mode === 'EMAIL') return this.requestOTP();
  }

  public onValidate(): void {
    if (this.validationButtonDisabled) return;
    if (this.mode === 'PIN') return this.pinValidation();
    if (this.mode === 'OTP') return this.otpValidation();
    if (this.mode === 'EMAIL') return this.emailValidation();
  }

  public onResend(): void {
    if (this.mode === 'PIN') return this.resetCardPass();
    this.requestPinOtp();
  }

  public closeDialog(data?: MarketCardValidationDialogResponse): void {
    this.dialogRef.close(data);
  }

  private getIntegratorOptions(integrator: string): SMLoyaltyConfiguration {
    const boxConfig = this.configService.getConfiguration();
    if (!boxConfig) return undefined;
    const smLoyaltyInfo = boxConfig.smLoyaltyInfo || [];
    return smLoyaltyInfo.find((info) => info.integrator === integrator);
  }

  private setDialogContent(): void {
    this.phoneNumber = this.validationConfig.phoneNumber;
    this.cardNumber = this.validationConfig.cardNumber;
    this.email = this.validationConfig.email;
    this.otpId = this.validationConfig.otpId;
    this.verificationToken = this.validationConfig.verificationToken;
    this.resendText = this.getResendText(this.mode);
    this.resendButtonText = this.getResendButtonText(this.mode);
    this.integratorName = this.shop.integrator.company;
    this.integratorOptions = this.getIntegratorOptions(this.integratorName);
    this.shopName = this.integratorOptions.smName;
    this.shopNumber = this.integratorOptions.contactPhone;
    this.logo = generateImageSrc(this.integratorOptions.superMarketLogoWide);
    this.logoAlt = this.shopName;
  }

  private getResendText(mode: string): string {
    if (mode === 'OTP') return 'didnt_you_get_the_text';
    if (mode === 'PIN') {
      if (this.reason === 'login') {
        return 'forgot_your_password';
      } else {
        return 'didnt_you_get_the_text';
      }
    }
    if (mode === 'EMAIL') return 'didnt_you_get_the_email';
  }

  private getResendButtonText(mode: string): string {
    if (mode === 'OTP' || mode === 'EMAIL') return 'send_again';
    if (mode === 'PIN') return 'click_here';
  }

  private setValidationFormControl(): void {
    this.validationButtonDisabled = true;
    this.validationFormControl = new FormControl('');
    this.validationFormControl.valueChanges.subscribe(
      (value: string) => (this.validationButtonDisabled = value.length === 0)
    );
  }

  private pinValidation(): void {
    this.loaderService.setState(true);
    const address = this.globalStateService.getAddress();
    const options: MarketCardConnectOptions = {
      save: this.saveCard,
      cardId: this.cardNumber,
      pass: this.validationFormControl.value as string,
      supermarket: this.integratorName,
      shopId: this.shop._id,
      addressObject: {
        street: address.street,
        streetNo: address.streetNo,
        postalCode: address.postalCode,
        city: address.city,
        longitude: address.longitude,
        latitude: address.latitude,
        nameAtBell: address.nameAtBell,
        floor: address.floor
      }
    };
    this.marketLoyaltyService
      .connectCard(options)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (cardDetails) => this.closeDialog({ success: true, cardDetails, save: this.saveCard }),
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private otpValidation(): void {
    this.loaderService.setState(true);
    const options: MarketVerifyOTPOptions = {
      save: this.saveCard,
      msisdn: this.verificationToken,
      otp: this.validationFormControl.value as string,
      otpId: this.otpId,
      supermarket: this.integratorName
    };
    this.marketLoyaltyService
      .verifyCardOTP(options)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (cardDetails) => {
          this.closeDialog({ success: true, cardDetails, save: this.saveCard });
        },
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private emailValidation(): void {
    const otp = this.validationFormControl.value as string;
    if (this.reason === 'create') return this.closeDialog({ success: true, otp, otpId: this.otpId });
    this.loaderService.setState(true);
    const options: MarketVerifyOTPOptions = {
      save: this.saveCard,
      email: this.email,
      otp,
      otpId: this.otpId,
      supermarket: this.integratorName
    };
    this.marketLoyaltyService
      .verifyCardOTP(options)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (cardDetails) => {
          this.closeDialog({ success: true, cardDetails, save: this.saveCard });
        },
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private resetCardPass(): void {
    this.loaderService.setState(true);
    const options: MarketCardPasswordResetOptions = { cardId: this.cardNumber, supermarket: this.integratorName };
    this.marketLoyaltyService
      .resetCardPassword(options)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private requestOTP(): void {
    this.loaderService.setState(true);
    const options: MarketOTPOptions = {
      email: this.email,
      cardId: this.cardNumber,
      supermarket: this.shop.integrator.company,
      reason: this.reason
    };
    this.marketLoyaltyService
      .requestCardOTP(options)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (response) => {
          this.otpId = response.otpId;
          this.maskedEmail = response.email;
          this.verificationToken = response.verificationToken;
          this.cardNumber = response.cardId;
          this.maskedPhoneNumber = response.mobile;
        },
        error: (error: APIError) => {
          this.dialogService.openErrorDialog(error);
          this.closeDialog({ success: false });
        }
      });
  }
}
