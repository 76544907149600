import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, Subscription, switchMap } from 'rxjs';
import {
  ConfirmDialogResponse,
  ProductReplacementOption,
  SeoOptions,
  Shop,
  User,
  CheckoutSuggestionBanner,
  Payment,
  OrderCreateOptions,
  APIError,
  Invoice,
  CheckoutBagsConfig,
  Order
} from '@box-types';
import {
  LoaderService,
  DialogService,
  OrdersService,
  SEOService,
  ShopService,
  ShopsService,
  UserService,
  NotificationsService,
  DeliveryMethodService,
  PaymentTypesService,
  CartService
} from '@box-core/services';
import { checkoutCommentValidator, phoneValidator } from '@box-shared/validators';
import { CheckoutPaymentTypesComponent, CheckoutPointsComponent } from '@box-checkout/components';
import { CartSuggestionService, CheckoutCouponsService, CheckoutService, CheckoutStateService } from '../../services';

import { startWith, pairwise, tap } from 'rxjs/operators';
import {
  getCheckoutCartCtaText,
  isPaymentCard,
  getProductReplacementOptions,
  getNextTimeslot,
  getCheckoutBagsConfig,
  itemsPriceIsOverMinimum,
  isAddressReadyForDelivery,
  isCouponDummy,
  discountMeetsCouponRequirements,
  getOffersForOrder,
  getProductsForOrder,
  removeCartCollectionFromStorage
} from '@box/utils';
import { TimeslotsService } from '@box-core/services/timeslots.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

const EMPTY_PAYMENT_INFO_DATA = { messages: ['please_choose_a_payment_method'] };

@Component({
  selector: 'checkout',
  templateUrl: './checkout.page.html',
  styleUrls: ['./checkout.page.scss']
})
export class CheckoutPage implements OnInit, OnDestroy {
  @ViewChild('paymentTypes') public paymentTypesRef: CheckoutPaymentTypesComponent;
  @ViewChild(CheckoutPointsComponent) public checkoutPointsComponent: CheckoutPointsComponent;

  public commentFormControl: FormControl;
  public phoneFormControl: FormControl;
  public shop: Shop;
  public hasComment = false;
  public cartPriceWithPureDiscounts: number;
  public cartStartingPrice: number;
  public totalDiscount: number;
  public totalPrice: number;
  public cartTotalQuantity: number;
  public showCheckoutPoints: boolean;
  public showScheduledSection = false;
  public priceCoveredByCoupon: number;
  public bagsConfig: CheckoutBagsConfig;
  public cartCtaTitle: string;
  public multiplierSum: number;
  public pointsSum: number;
  public isSuperMarket: boolean;
  public hasLoyaltyCard: boolean;
  public hasReplacementOption: boolean;
  public hasInvoiceOption: boolean;
  public canSubmitOrder: boolean;
  public checkoutSuggestionBanners: CheckoutSuggestionBanner[];
  public paymentType: Payment;
  public tipEnabled: boolean;
  public donationEnabled: boolean;
  public checkoutPointsDisabled: boolean;

  private user: User;
  private userSubscription: Subscription;
  private couponSubscription: Subscription;
  private paymentTypeSubscription: Subscription;
  private cartSubscription: Subscription;
  private shopSubscription: Subscription;
  private orderPreviewSubscription: Subscription;
  private benefitsSubscription: Subscription;
  private updateOrderPreviewSubscription: Subscription;
  private invoice: Invoice;
  private invoiceSubscription: Subscription;
  private bankLoyaltyRedemption: Subscription;
  private productReplacementOption: ProductReplacementOption;
  private productReplacementSubscription: Subscription;
  private marketSubscription: Subscription;
  private pointsSubscription: Subscription;
  private happyHourSubscription: Subscription; // This needs to be removed after the Checkout Refactor
  private deliveryMethodSubscription: Subscription;
  private canSubmitSubscription: Subscription;

  @HostBinding('class') public pageWrapper = 'page-wrapper checkout-page';

  constructor(
    private router: Router,
    private shopService: ShopService,
    private seoService: SEOService,
    private checkoutCouponsService: CheckoutCouponsService,
    private dialogService: DialogService,
    private ordersService: OrdersService,
    private loaderService: LoaderService,
    private paymentTypesService: PaymentTypesService,
    private userService: UserService,
    private cartSuggestionService: CartSuggestionService,
    private shopsService: ShopsService,
    private timeslotsService: TimeslotsService,
    private checkoutService: CheckoutService,
    private notificationsService: NotificationsService,
    private deliveryMethodService: DeliveryMethodService,
    private cartService: CartService,
    private checkoutStateService: CheckoutStateService,
    private globalStateService: GlobalStateService
  ) {}

  ngOnInit(): void {
    this.setShopSubscription();
    this.setProductReplacementOptions();
    this.setCartSubscription();
    this.setBenefitsSubscription();
    this.setInvoiceSubscription();
    this.setPointsSubscription();
    this.setUpdateOrderPreviewSubscription();
    this.setUserSubscription();
    this.setTimeslotSubscription();
    this.setMarketSubscription();
    this.setHappyHourExpirationSubscription();
    this.setUpdateOrderPreviewSubscription();
    this.setOrderPreviewSubscription();
    this.setMetaTags();
    this.setPhoneFormControl();
    this.setCommentFormControl();
    this.checkoutService.initializeCheckoutPayments();
    this.paymentTypesService.setDefaultPaymentType();
    this.checkoutService.handleSynergyDialog();
    this.deliveryMethodService.initializeMethod(this.shopService.getShop());
    this.initializeCheckoutBanners();
    this.setCanSubmitValueSubscription();
    this.setPaymentTypeSubscription();
    this.setDeliveryMethodSubscription();
  }

  ngOnDestroy(): void {
    this.timeslotsService.clearTimeslots();
    this.globalStateService.clearOrder();
    this.clearSubscriptions();
    this.loaderService.setState(false);
  }

  private clearSubscriptions(): void {
    this.couponSubscription?.unsubscribe();
    this.paymentTypeSubscription?.unsubscribe();
    this.cartSubscription?.unsubscribe();
    this.invoiceSubscription?.unsubscribe();
    this.productReplacementSubscription?.unsubscribe();
    this.marketSubscription?.unsubscribe();
    this.shopSubscription?.unsubscribe();
    this.updateOrderPreviewSubscription?.unsubscribe();
    this.happyHourSubscription?.unsubscribe();
    this.deliveryMethodSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.orderPreviewSubscription?.unsubscribe();
    this.benefitsSubscription?.unsubscribe();
    this.bankLoyaltyRedemption?.unsubscribe();
    this.pointsSubscription?.unsubscribe();
    this.canSubmitSubscription?.unsubscribe();
  }

  private initializeCheckoutBanners(): void {
    this.cartSuggestionService
      .getCheckoutSuggestionBanners$()
      .subscribe((checkoutSuggestionBanners) => (this.checkoutSuggestionBanners = checkoutSuggestionBanners));
  }

  private setUserSubscription(): void {
    this.userSubscription = this.globalStateService.user$.subscribe((user: User) => {
      this.user = user;
      this.showCheckoutPoints = this.checkoutService.getCheckoutPointsVisibility(
        this.shopService.getShop(),
        this.checkoutStateService.getCartPriceWithPureDiscounts()
      );
    });
  }

  private setUpdateOrderPreviewSubscription(): void {
    this.updateOrderPreviewSubscription = combineLatest([
      this.checkoutStateService.bags$,
      this.checkoutStateService.donation$,
      this.checkoutStateService.tip$,
      this.checkoutStateService.pointsDiscount$,
      this.checkoutCouponsService.coupon$,
      // changing the address, makes as call canDeliverToCoords, which responds with an updated version of the shop which could have different deliveryFes
      this.shopService.shop,
      this.deliveryMethodService.deliveryMethod,
      this.cartService.cart$,
      this.checkoutStateService.marketPointsChecked$,
      this.checkoutStateService.marketOrderCheck$,
      this.checkoutStateService.bankLoyaltyRedemption$
    ]).subscribe(() => {
      this.checkoutService.updateCheckoutOrderPreview();
    });
  }

  private setCanSubmitValueSubscription(): void {
    this.canSubmitSubscription = combineLatest([
      this.phoneFormControl.valueChanges,
      this.commentFormControl.valueChanges,
      this.deliveryMethodService.deliveryMethod$,
      this.cartService.cart$,
      this.checkoutStateService.checkoutOrderPreview$,
      this.checkoutStateService.marketOrderCheck$,
      this.shopService.shop
    ]).subscribe(() => {
      this.canSubmitOrder = this.getCanSubmitValue();
    });
    this.initializePhoneFormControlValue();
    this.initializeCommentFormControl();
  }

  private setOrderPreviewSubscription(): void {
    this.orderPreviewSubscription = this.checkoutStateService.checkoutOrderPreview$.subscribe(
      (checkoutOrderPreview) => {
        this.priceCoveredByCoupon = checkoutOrderPreview.priceCoveredByCoupon;
        this.cartPriceWithPureDiscounts = checkoutOrderPreview.cartPriceWithPureDiscounts;
        this.cartStartingPrice = checkoutOrderPreview.cartStartingPrice;
        this.totalDiscount = checkoutOrderPreview.totalDiscount;
        this.totalPrice = checkoutOrderPreview.totalPrice;
        this.showCheckoutPoints = this.checkoutService.getCheckoutPointsVisibility(
          this.shopService.getShop(),
          checkoutOrderPreview.cartPriceWithPureDiscounts
        );
      }
    );
  }

  private getCanSubmitValue(): boolean {
    const validPhone = this.phoneFormControl.valid;
    const validComment = this.commentFormControl.valid;
    const hasItemsInCart = this.cartService.getCart().itemsQuantity > 0; // the checkout resolver already handles that case
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    const itemsPrice =
      this.checkoutStateService.getCartPriceWithPureDiscounts() +
      this.checkoutStateService.getCheckoutOrderPreview().totalEnvFee;
    const sufficientMinimumPrice = itemsPriceIsOverMinimum(this.shopService.getShop(), deliveryMethod, itemsPrice);
    const marketCheckOrderIsValid = this.isSuperMarket
      ? Boolean(this.checkoutStateService.getMarketOrderCheck())
      : true;
    return validPhone && hasItemsInCart && sufficientMinimumPrice && validComment && marketCheckOrderIsValid;
  }

  // shop may be updated after address changes or daas availability is called
  private setShopSubscription(): void {
    this.shopSubscription = this.shopService.shop.subscribe((shop) => {
      this.shop = shop;
      const { hasInvoiceOption, isSuperMarket, hasLoyaltyCard } = shop;
      this.tipEnabled = this.checkoutService.isCheckoutTipEnabled();
      this.hasInvoiceOption = hasInvoiceOption;
      this.isSuperMarket = isSuperMarket;
      this.hasLoyaltyCard = hasLoyaltyCard;
    });
  }

  private setHappyHourExpirationSubscription(): void {
    this.happyHourSubscription = this.checkoutService
      .getHappyHourExpiration$()
      .subscribe(() => this.happyHourSubscription?.unsubscribe());
  }

  private setCartSubscription(): void {
    this.cartSubscription = this.cartService.cart$.subscribe((cart) => {
      this.cartTotalQuantity = cart.itemsQuantity;
      if (this.isSuperMarket) {
        this.checkoutService.checkMarketOrder().subscribe((marketOrderCheck) => {
          this.bagsConfig = getCheckoutBagsConfig(this.shopService.getShop(), this.cartTotalQuantity, marketOrderCheck);
        });
      } else {
        this.bagsConfig = getCheckoutBagsConfig(this.shopService.getShop(), this.cartTotalQuantity);
      }
    });
  }

  private setMarketSubscription(): void {
    if (!this.isSuperMarket) return;
    this.marketSubscription = combineLatest([
      this.checkoutStateService.marketPointsChecked$,
      this.checkoutStateService.marketOrderCheck$
    ]).subscribe(([pointsChecked, marketOrderCheck]) => {
      this.bagsConfig = getCheckoutBagsConfig(this.shopService.getShop(), this.cartTotalQuantity, marketOrderCheck);
    });
  }

  private setInvoiceSubscription(): void {
    this.invoiceSubscription = combineLatest([
      this.checkoutStateService.invoiceChecked$,
      this.checkoutStateService.invoice$
    ]).subscribe(([checked, invoice]) => {
      if (checked === true && invoice) return (this.invoice = invoice);
      this.invoice = null;
    });
  }

  private setProductReplacementOptions() {
    const options = getProductReplacementOptions(this.shopService.getShop());
    if (options.length === 0) return (this.hasReplacementOption = false);
    this.checkoutStateService.setReplacementOptions(options);
    this.checkoutStateService.setReplacementOption(options[0]);
    this.hasReplacementOption = true;
    this.productReplacementSubscription = this.checkoutStateService.replacementOption$.subscribe(
      (option) => (this.productReplacementOption = option)
    );
  }

  private setMetaTags(): void {
    const options: SeoOptions = { title: 'order_completion', url: this.router.url };
    this.seoService.setTags(options);
  }

  private setFoodTimeslotData(): void {
    const timeslots = (this.shopService.getShop().timeslots ?? []).filter((t) => t.isAvailable);
    this.timeslotsService.setTimeslots(timeslots);
    this.showScheduledSection = this.shopService.getShop().hasTimeSlots;
  }

  private setMarketTimeslotData(): void {
    const timeslots = (this.shopService.getShop().timeslots ?? []).filter((t) => t.isAvailable);
    this.timeslotsService.setTimeslots(timeslots);
    const currentTimeslot = this.timeslotsService.getTimeslot();
    if (!currentTimeslot) this.timeslotsService.setTimeslot(getNextTimeslot(timeslots));
    this.showScheduledSection = this.shopService.getShop().hasTimeSlots;
  }

  private setTimeslotSubscription(): void {
    if (!this.isSuperMarket) this.setFoodTimeslotData();
    if (this.isSuperMarket) this.setMarketTimeslotData();
  }

  private setPhoneFormControl(): void {
    // There is an issue with the Validators.required validation
    // eslint-disable-next-line
    this.phoneFormControl = new FormControl('', Validators.compose([Validators.required, phoneValidator()]));
  }

  private initializePhoneFormControlValue(): void {
    const phone = this.user?.contactPhone ?? '';
    this.phoneFormControl.setValue(phone);
    this.phoneFormControl.markAsTouched({ onlySelf: true });
  }

  private setCommentFormControl(): void {
    this.commentFormControl = new FormControl(
      '',
      Validators.compose([Validators.maxLength(500), checkoutCommentValidator()])
    );
  }

  private initializeCommentFormControl(): void {
    this.commentFormControl.setValue('');
    this.commentFormControl.markAsTouched({ onlySelf: true });
  }

  onChangeCommentSlider(): void {
    this.hasComment = !this.hasComment;
    this.checkoutService.triggerCommentAnalyticsEvent(this.hasComment);
    this.commentFormControl.reset();
  }

  onOrderSubmit(): void {
    if (!this.canSubmitOrder) return;
    if (!isAddressReadyForDelivery(this.globalStateService.getAddress())) {
      return this.checkoutService.problematicAddressFlow();
    }
    const selectedPayment = this.paymentTypesService.getPaymentType();
    if (selectedPayment.type === 'empty') return void this.dialogService.openInfoDialog(EMPTY_PAYMENT_INFO_DATA);
    const showCheckOrderDialog = this.checkoutService.shouldShowCheckOrderDifferenceDialog();
    if (!showCheckOrderDialog) return this.createOrder();
    this.checkoutService
      .openCheckOrderPriceConfirmDialog()
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (data?.accepted) this.createOrder();
      });
  }

  private getOrderComment(): string {
    const comments: string[] = [];
    const comment = this.commentFormControl.value as string;
    if (comment?.length > 0) comments.push(comment);
    const selectedAddress = this.globalStateService.getAddress();
    if (selectedAddress?.comments?.length > 0) comments.push(selectedAddress.comments);
    return comments.join(', ');
  }

  private generateOrderCreateOptions(): OrderCreateOptions {
    const bagsState = this.checkoutStateService.getBagsState();
    const selectedPayment = this.paymentTypesService.getPaymentType();
    const options: OrderCreateOptions = {
      paymentType: selectedPayment.type,
      paidWithNewCard: Boolean(selectedPayment.addNew),
      addressObject: this.globalStateService.getAddress(),
      changesAllowed: 'replace',
      wantPlasticBags: Boolean(bagsState?.checked),
      shop: this.shopService.getShop()._id,
      comments: this.getOrderComment(),
      orderContactPhone: this.phoneFormControl.value as string,
      myoBoxakiRedeem: this.checkoutStateService.getPointsDiscount() > 0,
      integratorRedeemPoints: this.checkoutStateService.getMarketPointsChecked(),
      cartTotal: this.totalPrice,
      orderShippingType: this.deliveryMethodService.getDeliveryMethod()
    };

    const coupon = this.checkoutCouponsService.getCoupon();
    const pointsDiscount = this.checkoutStateService.getPointsDiscount();
    if (pointsDiscount > 0) options.myoBoxakiEuroDiscountToRedeem = pointsDiscount / 100;
    const dummyCouponSelected = Boolean(coupon) && isCouponDummy(coupon);
    if (dummyCouponSelected) options.initiatePromos = [coupon.triggerPromoInitiation];
    const bankLoyaltyDiscount = Boolean(this.checkoutStateService.getBankLoyaltyRedemption()?.discount);
    if (bankLoyaltyDiscount) {
      const { cardBoxId, pointsToRedeem } = this.checkoutStateService.getBankLoyaltyRedemption();
      options.discounts = { bankCardLoyalty: { cardBoxId, pointsToRedeem } };
    }
    if (coupon?.code) options.couponCode = coupon.code;
    const timeslot = this.timeslotsService.getTimeslot();
    if (timeslot) {
      if (timeslot.originalAA) options.timeSlotId = String(timeslot.originalAA);
      options.timeSlotStart = timeslot.timeSlotStart;
      options.timeSlotEnd = timeslot.timeSlotEnd;
    }
    const selectedCampaigns = this.checkoutService.getOrderPromoCampaigns();
    if (selectedCampaigns.length) options.selectedCampaigns = selectedCampaigns;
    const { offers: cartOffers, products: cartProducts } = this.cartService.getCart();
    const products = getProductsForOrder(cartProducts);
    if (products.length) options.products = products;
    const offers = getOffersForOrder(cartOffers);
    if (offers.length) options.offers = offers;
    if (this.invoice) options.invoice = this.invoice;
    if (bagsState?.quantity) options.plasticBagsQuantity = bagsState.quantity;
    if (this.checkoutService.isCheckoutTipEnabled()) options.tip = this.checkoutStateService.getTip();
    if (this.donationEnabled) options.donation = this.checkoutStateService.getDonation();
    if (this.isSuperMarket) {
      const loyaltyCard = this.checkoutService.getSuperMarketLoyaltyCard();
      if (loyaltyCard) {
        options.cardId = loyaltyCard.cardId;
        options.customerToken = loyaltyCard.customerToken;
      }
    }
    if (this.productReplacementOption) options[this.productReplacementOption.shopProperty] = true;

    return options;
  }

  private createOrder(): void {
    if (!this.canSubmitOrder) return;
    /**
     *  this.loaderService.setState(false); had to be removed from pipe finalize due to token/validate stoping the loader
     *  additionally the actions we are doing inside next, give the user a time window to order twice
     *  giving the client the chance to click the submit order button again.
     *  removing the cart collection from storage, allows us to catch the case where the user refreshes during the period
     *  we are waiting for a response from create order, and then resubmits the order
     *  */
    removeCartCollectionFromStorage(this.shop.collectionType, window.localStorage);
    this.loaderService.setState(true);
    const orderCreateOptions = this.generateOrderCreateOptions();
    this.ordersService
      .createOrder(orderCreateOptions)
      .pipe(switchMap((order: Order) => this.checkoutService.orderLoyaltyStatusPolling(order)))
      .subscribe({
        next: (order) => {
          this.prepareInCasePaymentFails(order);
          this.checkoutService.handlePayment(order);
          // Since we use caching on those services, after a merchantCampaign claim, we need the new shop models
          this.shopsService.triggerShopsCacheInvalidation();
          this.globalStateService.setOrder(order);
          this.checkoutService.handleOrderResponse(order);
          this.userService.updateUserHasOrdered(true);
          this.checkoutService.triggerPurchaseAnalyticsEvent(order);
          if (orderCreateOptions.couponCode) this.checkoutService.triggerOrderFromCouponAnalyticsEvent();
          this.notificationsService.removeAllNotifications();
        },
        error: (error: APIError) => {
          this.loaderService.setState(false);
          if (error?.code === 'SHOP_NOT_REGISTERED_CAMPAIGN') {
            this.dialogService.openConfirmDialog({
              title: 'you_have_already_earned_6x_points',
              messages: ['you_have_already_earned_6x_points_from_your_first_order_in_the_shop'],
              confirmText: 'confirm_',
              cancelText: 'back_to'
            });
          } else {
            this.dialogService.openErrorDialog(error);
          }
        }
      });
  }

  private prepareInCasePaymentFails(order: Order): void {
    if (!order) return;
    if (order.shopResponse?.status !== 'PAYMENT_PENDING') return;
    if (!order.marketPlacePoints?.consumed) return;
    this.userService.saveRemainingPointsToStorage(order._id);
  }

  private setPaymentTypeSubscription(): void {
    this.paymentTypeSubscription = this.paymentTypesService.payment$
      .pipe(tap((payment) => (this.paymentType = payment)))
      .subscribe((payment) => {
        const isCard = isPaymentCard(payment);
        if (!isCard) {
          this.checkoutStateService.setTip(0);
          this.checkoutStateService.setDonation(0);
        }
        this.checkoutPointsDisabled = !isCard;
        this.tipEnabled = this.checkoutService.isCheckoutTipEnabled();
        this.donationEnabled = this.checkoutService.isCheckoutDonationEnabled();
        this.cartCtaTitle = getCheckoutCartCtaText(payment);
        if (payment?.type === 'cash') {
          this.resetPointsComponent();
          this.checkoutCouponsService.clearCoupon();
          this.checkoutStateService.setBankLoyaltyRedemption(null);
        }
      });
  }

  private setDeliveryMethodSubscription(): void {
    this.deliveryMethodSubscription = this.deliveryMethodService.deliveryMethod
      .pipe(startWith(null), pairwise())
      .subscribe(([previousDeliveryMethod, currentDeliveryMethod]) => {
        this.checkoutService.checkShopMinimumPriceAfterTakeAwaySelection(currentDeliveryMethod);
        this.tipEnabled = this.checkoutService.isCheckoutTipEnabled();
        this.donationEnabled = this.checkoutService.isCheckoutDonationEnabled();
        this.checkoutService.initializeCheckoutPayments();
        const changedToTakeAway = previousDeliveryMethod === 'delivery' && currentDeliveryMethod === 'takeAway';
        if (changedToTakeAway) this.checkoutService.handleTakeAwayPaymentSelection();
      });
  }

  private setPointsSubscription(): void {
    // https://www.notion.so/desquared/Mark-specific-coupons-WU-not-combined-with-points-redemption-913475bbd86c42639f4f0a934e28f857?pvs=4
    this.pointsSubscription = this.checkoutStateService.pointsDiscount$.subscribe((cents) => {
      const pointsDiscount = cents;
      const coupon = this.checkoutCouponsService.getCoupon();
      const meetsRequirements =
        !coupon ||
        discountMeetsCouponRequirements(
          coupon,
          pointsDiscount,
          this.checkoutStateService.getCartPriceWithPureDiscounts()
        );
      if (meetsRequirements) return;

      this.checkoutCouponsService
        .showCouponDiscountRemovalDialog(
          coupon,
          pointsDiscount,
          this.checkoutStateService.getCartPriceWithPureDiscounts()
        )
        .afterClosed()
        .subscribe((response: ConfirmDialogResponse) => {
          if (!response?.accepted) return this.resetPointsComponent();
          this.checkoutCouponsService.clearCoupon();
        });
    });
  }

  private resetPointsComponent(): void {
    if (!this.checkoutPointsComponent) return;
    this.checkoutPointsComponent.reset();
  }

  private setBenefitsSubscription(): void {
    this.benefitsSubscription = combineLatest([
      this.checkoutCouponsService.coupon$,
      this.cartService.cart$,
      this.paymentTypesService.payment$
    ]).subscribe(() => {
      this.checkoutService.setPointsAndMultiplierSum();
      this.multiplierSum = this.checkoutStateService.getMultiplierSum();
      this.pointsSum = this.checkoutStateService.getPointsSum();
    });
  }
}
