import {
  Category,
  Order,
  Product,
  Shop,
  Payment,
  DeliveryMethod,
  GAAddPaymentInfoConfig,
  GAAddShippingInfoConfig,
  GAEcommerceItemConfig,
  GAPurchaseConfig,
  MPPurchaseConfig,
  PurchaseEvent,
  Coupon,
  Offer,
  Cart
} from '@box-types';
import {
  getCartOfferBeginPrice,
  getCartOfferFinalPrice,
  getCartProductBeginPrice,
  getCartProductFinalPrice
} from '../cart';

export {
  getPurchaseEventGAConfig,
  getPurchaseEventItems,
  getPurchaseEventCategory,
  getPurchaseEventOfferDiscount,
  getPurchaseEventProductDiscount,
  getAddShippingInfoEventGAConfig,
  getAddPaymentInfoEventGAConfig,
  getPurchaseEventMPConfig
};

const MAX_NUMBER_OF_ITEMS = 200;

type getCollectionCheckoutConfigOptions = {
  order?: Order;
  cart: Cart;
  shop: Shop;
  purchaseEvent: PurchaseEvent;
  categories: Category[];
  coupon?: Coupon;
  deliveryFee?: number;
  deliveryMethod?: DeliveryMethod;
  paymentType?: Payment;
};

function getPurchaseEventCategory(categories: Category[], product: Product): string {
  const productCategory = product.category;
  if (!productCategory) return '';
  const parentCategory = categories.find((category) => category._id === productCategory.parent);
  if (parentCategory) return parentCategory.name;
  const category = categories.find((category) => category._id === productCategory._id);
  if (category) return category.name;
  return '';
}

function getPurchaseEventOfferDiscount(offer: Offer): number {
  const discount = getCartOfferBeginPrice(offer) - getCartOfferFinalPrice(offer);
  return discount / 100;
}

function getPurchaseEventProductDiscount(product: Product): number {
  const discount = getCartProductBeginPrice(product) - getCartProductFinalPrice(product);
  return discount / 100;
}

function getPurchaseEventItems(
  shop: Shop,
  offers: Offer[],
  products: Product[],
  categories: Category[],
  couponCode: string,
  purchaseShopEvent: PurchaseEvent
): GAEcommerceItemConfig[] {
  const gaItems: GAEcommerceItemConfig[] = [];
  offers.forEach((offer) => {
    offer.cartInstances.forEach((instance) => {
      // todo pats, empty values
      gaItems.push({
        item_id: offer._id,
        item_name: offer.name,
        affiliation: shop.name,
        coupon: couponCode,
        discount: getPurchaseEventOfferDiscount(offer),
        item_category: '',
        item_category2: shop.secondaryCuisines[0]?.name ?? '',
        item_category3: shop.chain ?? '',
        item_list_id: '',
        item_list_name: purchaseShopEvent?.itemListName ?? '',
        item_variant: '',
        price: getCartOfferFinalPrice(offer) / 100,
        quantity: instance.quantity
      });
    });
  });

  products.forEach((product) =>
    product.cartInstances.forEach((instance) =>
      gaItems.push({
        item_id: product._id,
        item_name: product.name,
        affiliation: shop.name,
        coupon: couponCode,
        discount: getPurchaseEventProductDiscount(product),
        item_brand: product.brand?.name ?? '',
        item_category: getPurchaseEventCategory(categories, product),
        item_category2: shop.secondaryCuisines[0]?.name ?? '',
        item_category3: shop.chain ?? '',
        item_list_id: '',
        item_list_name: purchaseShopEvent?.itemListName ?? '',
        item_variant: '',
        price: getCartProductFinalPrice(product) / 100,
        quantity: instance.quantity
      })
    )
  );

  return gaItems.slice(0, MAX_NUMBER_OF_ITEMS);
}

function getPurchaseEventGAConfig(options: getCollectionCheckoutConfigOptions): GAPurchaseConfig {
  const order = options.order;
  const cart = options.cart;
  const shop = options.shop;
  const purchaseShopEvent = options.purchaseEvent;
  const categories = options.categories;
  const deliveryFee = options.deliveryFee;
  if (!order) return;
  const couponCode = order.coupon?.code ?? '';
  const gaItems: GAEcommerceItemConfig[] = getPurchaseEventItems(
    shop,
    cart.offers,
    cart.products,
    categories,
    couponCode,
    purchaseShopEvent
  );
  return {
    transaction_id: order._id,
    value: order.totalPrice / 100,
    tax: 0,
    shipping: deliveryFee / 100,
    coupon: couponCode,
    currency: 'EUR',
    item_id: shop._id,
    item_name: shop.name,
    creative_name: purchaseShopEvent?.creativeName ?? '',
    creative_slot: purchaseShopEvent?.creativeSlot ?? '',
    item_list_name: purchaseShopEvent?.itemListName ?? '',
    promotion_name: purchaseShopEvent?.promotionName ?? '',
    index: purchaseShopEvent?.index ?? 0,
    item_variant: '',
    items: gaItems
  };
}

function getAddShippingInfoEventGAConfig(options: getCollectionCheckoutConfigOptions): GAAddShippingInfoConfig {
  const coupon = options.coupon;
  const cart = options.cart;
  const shop = options.shop;
  const purchaseShopEvent = options.purchaseEvent;
  const categories = options.categories;
  const deliveryMethod = options.deliveryMethod;
  if (!cart) return;
  const couponCode = coupon?.code ?? '';
  const gaItems: GAEcommerceItemConfig[] = getPurchaseEventItems(
    shop,
    cart.offers,
    cart.products,
    categories,
    couponCode,
    purchaseShopEvent
  );
  return {
    currency: 'EUR',
    value: cart.itemsFinalPrice / 100,
    coupon: couponCode,
    shipping_tier: deliveryMethod,
    items: gaItems.slice(0, MAX_NUMBER_OF_ITEMS)
  };
}

function getAddPaymentInfoEventGAConfig(options: getCollectionCheckoutConfigOptions): GAAddPaymentInfoConfig {
  const coupon = options.coupon;
  const cart = options.cart;
  const shop = options.shop;
  const purchaseShopEvent = options.purchaseEvent;
  const categories = options.categories;
  const paymentType = options.paymentType;
  if (!cart) return;
  const couponCode = coupon?.code ?? '';
  const gaItems: GAEcommerceItemConfig[] = getPurchaseEventItems(
    shop,
    cart.offers,
    cart.products,
    categories,
    couponCode,
    purchaseShopEvent
  );
  return {
    currency: 'EUR',
    value: cart.itemsFinalPrice / 100,
    coupon: couponCode,
    payment_type: paymentType.type,
    items: gaItems.slice(0, MAX_NUMBER_OF_ITEMS)
  };
}

function getPurchaseEventMPConfig(order: Order, shopName: string): MPPurchaseConfig {
  if (!order) return;
  return { value: order.totalPrice / 100, currency: 'EUR', store_name: shopName };
}
