import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@box-env/environment';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  APIResponse,
  Coupon,
  CouponCheckOptions,
  FetchCouponsForCheckoutOptions,
  SelectedCouponSession,
  SelectedCouponSessionSource,
  Shop
} from '@box-types';
import {
  isCouponValid,
  sortAvailableCoupons,
  sortUnavailableCoupons,
  isCouponKnown,
  ObservableCacheDecorator,
  storageSet,
  storageGet,
  getCouponEligibleShops,
  filterWalletCoupons,
  storageRemove
} from '@box/utils';
import pickBy from 'lodash-es/pickBy';
import { ConfigurationService } from './configuration.service';
import { NotificationsService } from './notifications.service';
import { InfoNotificationWidgetData } from '@box-shared/components/info-notification-wrapper/info-notification-wrapper.types';
import { InfoNotificationWrapperComponent } from '@box-shared/components';
import { CoreService } from './core.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

const AVAILABLE_COUPONS_SESSION_EXPIRATION = 2 * 60 * 1000; // 2 minutes
const UNAVAILABLE_COUPONS_SESSION_EXPIRATION = 2 * 60 * 1000; // 2 minutes
const invalidationSignalCouponShopSource = new Subject<string>();
const invalidationSignalCouponShop$: Observable<string> = invalidationSignalCouponShopSource.asObservable();
const SELECTED_COUPON_STORAGE_KEY = 'Box:selectedCoupon';

@Injectable({ providedIn: 'root' })
export class CouponsService {
  private readonly BOX_API = environment.application.API_URL;

  constructor(
    private coreService: CoreService,
    private http: HttpClient,
    private configService: ConfigurationService,
    private notificationsService: NotificationsService,
    private globalStateService: GlobalStateService
  ) {}

  // this function is never called but is here as an example of the invalidation mechanism
  public triggerShopCouponInvalidation(collectionType?: number): void {
    invalidationSignalCouponShopSource.next(collectionType?.toString());
  }

  public getDecoratedCouponsSubscription(): Subscription {
    return combineLatest([this.globalStateService.availableCoupons$, this.globalStateService.shops$]).subscribe(
      ([coupons, shops]) => {
        const decoratedCoupons = this.decorateCouponsWithEligibleShops(coupons, shops);
        const filteredCoupons = filterWalletCoupons(decoratedCoupons);
        this.globalStateService.setDecoratedCoupons(filteredCoupons);
      }
    );
  }

  public decorateCouponsWithEligibleShops(coupons: Coupon[], shops: Shop[]): Coupon[] {
    if (!coupons?.length) return [];
    if (!shops?.length) return coupons;
    const cuisines = this.coreService.cuisines.getValue();
    const chains = this.coreService.chains.getValue();
    return coupons.map((coupon) => {
      const eligibleShops = getCouponEligibleShops(coupon, shops, cuisines, chains);
      return { ...coupon, eligibleShops: eligibleShops };
    });
  }

  public addAvailableCoupon(coupon: Coupon): void {
    const currentCoupons = this.globalStateService.getAvailableCoupons();
    const coupons = sortAvailableCoupons([...currentCoupons, coupon]);
    this.globalStateService.setAvailableCoupons(coupons);
  }

  @ObservableCacheDecorator<Coupon[]>({ expirationTimeInMs: AVAILABLE_COUPONS_SESSION_EXPIRATION })
  public fetchAvailableCoupons$(): Observable<Coupon[]> {
    return this.http.get(`${this.BOX_API}/coupon-wallet/main-view/available`).pipe(
      map((response: APIResponse<{ coupons: Coupon[] }>) => {
        const coupons = response.payload.coupons;
        const validAndKnownCoupons = coupons.filter((coupon) => isCouponValid(coupon) && isCouponKnown(coupon));
        return sortAvailableCoupons(validAndKnownCoupons);
      })
    );
  }

  public addUnavailableCoupon(coupon: Coupon): void {
    const currentCoupons = this.globalStateService.getUnavailableCoupons();
    const coupons = sortUnavailableCoupons([...currentCoupons, coupon]);
    this.globalStateService.setUnavailableCoupons(coupons);
  }

  @ObservableCacheDecorator<Coupon[]>({ expirationTimeInMs: UNAVAILABLE_COUPONS_SESSION_EXPIRATION })
  public fetchUnavailableCoupons$(): Observable<Coupon[]> {
    return this.http.get(`${this.BOX_API}/coupon-wallet/main-view/unavailable`).pipe(
      map((response: APIResponse<{ coupons: Coupon[] }>) => {
        const coupons = response.payload.coupons;
        const dummyFilteredCoupons = this.filterDummyCoupons(coupons);
        const filteredCoupons = dummyFilteredCoupons.filter((coupon) => isCouponValid(coupon) && isCouponKnown(coupon));
        this.globalStateService.setUnavailableCoupons(filteredCoupons);
        return filteredCoupons;
      })
    );
  }

  public checkCoupon(code: string, options?: CouponCheckOptions): Observable<Coupon> {
    const pickedOptions = pickBy(options);
    const params = new HttpParams({ fromObject: pickedOptions });
    return this.http
      .get(`${this.BOX_API}/coupons/${code}/check`, { params })
      .pipe(map((response: APIResponse<{ coupon: Coupon }>) => response.payload.coupon));
  }

  public redeemCoupon(code: string): Observable<Coupon> {
    return this.http
      .post(`${this.BOX_API}/coupons/${code}/redeem`, {})
      .pipe(map((response: APIResponse<{ coupon: Coupon }>) => response.payload.coupon));
  }

  @ObservableCacheDecorator<Coupon[], number>({
    expirationTimeInMs: AVAILABLE_COUPONS_SESSION_EXPIRATION,
    functionKeyArgumentIndexes: [0],
    invalidateSignal$: invalidationSignalCouponShop$
  })
  public fetchShopCoupons(collectionType: number): Observable<Coupon[]> {
    return this.http.get(`${this.BOX_API}/coupon-wallet/shop/${collectionType}`).pipe(
      map((response: APIResponse<{ coupons: Coupon[] }>) => {
        const coupons = response.payload.coupons;
        return coupons.filter((coupon) => isCouponValid(coupon) && isCouponKnown(coupon));
      })
    );
  }

  public addCouponToWallet(code: string): Observable<Coupon> {
    return this.http
      .post(`${this.BOX_API}/coupon-wallet`, { code })
      .pipe(map((response: APIResponse<{ coupon: Coupon }>) => response.payload.coupon));
  }

  public fetchCheckoutCoupons(collectionType: number, options: FetchCouponsForCheckoutOptions): Observable<Coupon[]> {
    return this.http.post(`${this.BOX_API}/coupon-wallet/checkout/${collectionType}`, options).pipe(
      map((response: APIResponse<{ coupons: Coupon[] }>) => {
        const coupons = response.payload.coupons;
        const dummyFilteredCoupons = this.filterDummyCoupons(coupons);
        const validCoupons = dummyFilteredCoupons.filter((coupon) => isCouponValid(coupon) && isCouponKnown(coupon));
        return this.normalizeCoupons(validCoupons);
      })
    );
  }

  // we have added a FE switch in the config file that dictates whether we show dummy coupons or not
  public filterDummyCoupons(coupons: Coupon[]): Coupon[] {
    const showDummyCoupons = this.configService.getConfiguration()?.startWUSynergy;
    return coupons.filter((coupon: Coupon) => (showDummyCoupons ? true : !coupon.dummy));
  }

  private normalizeCoupons(coupons: Coupon[]): Coupon[] {
    if (!coupons?.length) return [];
    return coupons.map((coupon) => {
      const isCombinedWithPointsRedemption =
        coupon.isCombinedWithPointsRedemption === undefined || coupon.isCombinedWithPointsRedemption === true;
      return { ...coupon, isCombinedWithPointsRedemption };
    });
  }

  public setLastSelectedCoupon(coupon: Coupon, source: SelectedCouponSessionSource): void {
    if (!coupon) return;
    if (!coupon.code && !coupon.synergy) return;
    const couponData = { code: coupon.code, synergy: coupon.synergy, source } as SelectedCouponSession;
    storageSet(SELECTED_COUPON_STORAGE_KEY, couponData, window.sessionStorage);
  }

  public getLastSelectedCoupon(): SelectedCouponSession {
    return storageGet(SELECTED_COUPON_STORAGE_KEY, window.sessionStorage);
  }

  public removeLastSelectedCouponFromStorage(): void {
    storageRemove(SELECTED_COUPON_STORAGE_KEY, sessionStorage);
  }

  public showCouponExpirationNotification(couponCode: string): void {
    if (!couponCode) return;
    const lastSelectedCoupon = this.getLastSelectedCoupon();
    if (lastSelectedCoupon?.code !== couponCode) return;
    const notificationData: InfoNotificationWidgetData = {
      message: 'your_coupon_has_expired'
    };
    const notificationRef = this.notificationsService.addNotification(InfoNotificationWrapperComponent, {
      data: notificationData
    });
    notificationRef.dismiss({ delay: 5000 });
  }
}
