import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PromoCampaign,
  PromoCampaignBanner,
  Shop,
  ShopSuggestionBanner,
  BusinessVertical,
  APIResponse,
  ShopPromo,
  ShopRegisteredCampaign
} from '@box-types';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@box-env/environment';
import { map, take } from 'rxjs/operators';
import {
  getPromoCampaignImage,
  getPromoText,
  isLowOrderProbabilityUserWithCoupon,
  isCollectionCampaign,
  CollectionCampaign,
  getInShopPlacementBannerText,
  filterShopsByPromoCampaigns,
  PromoCampaignsItemsOptions,
  PromoCampaignsItemsResponse,
  isEnabledByTimeRanges,
  isWithinWeekDays,
  generateImageSrc,
  sortPaymentCampaigns,
  isItemEnabledForSegments,
  isPromoCampaignSpecial,
  sortPromoCampaigns,
  getShopBestMerchantSponsoredCampaign,
  getSpecialCampaigns,
  getShopTilePointsSum,
  getShopTileMultiplierSum,
  getSpecificPromoCampaignCoupons,
  isPromoCampaignOverriddenByCouponSynergies
} from '@box/utils';
import { UserService } from './user.service';
import { pickBy } from 'lodash-es';
import {
  ShortPromoVisualOptionsService,
  LongPromoVisualOptionsService
} from '@box-core/services/promo-options.service';
import { CouponsService } from './coupons.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

const MAXIMUM_PAYMENT_CAMPAIGNS_IN_VIEW = 2;

@Injectable({ providedIn: 'root' })
export class PromoCampaignsService {
  private BOX_API = environment.application.API_URL;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private shortPromoVisualOptionsService: ShortPromoVisualOptionsService,
    private longPromoVisualOptionsService: LongPromoVisualOptionsService,
    private globalStateService: GlobalStateService
  ) {}

  public fetchPromoCampaigns(): Observable<PromoCampaign[]> {
    return this.http
      .get(this.BOX_API + '/promos/campaigns')
      .pipe(map((response: APIResponse<{ promoCampaigns: PromoCampaign[] }>) => response.payload.promoCampaigns));
  }

  public getActivePromoCampaigns(): PromoCampaign[] {
    const promoCampaigns = this.globalStateService.getPromoCampaigns();
    return promoCampaigns.filter((promoCampaign) => this.isPromoCampaignActive(promoCampaign));
  }

  public getActivePromoCampaigns$(): Observable<PromoCampaign[]> {
    return this.globalStateService.promoCampaigns$.pipe(
      take(1),
      map((promoCampaigns) => promoCampaigns.filter((promoCampaign) => this.isPromoCampaignActive(promoCampaign)))
    );
  }

  public fetchPromoCampaignsItems(
    collectionType: number,
    options: PromoCampaignsItemsOptions
  ): Observable<PromoCampaignsItemsResponse> {
    const { promoCampaigns, supermarketGroup } = options;
    const fromObject = pickBy({ promoCampaigns: promoCampaigns?.join(','), supermarketGroup });
    const params = new HttpParams({ fromObject });

    return this.http
      .get(`${this.BOX_API}/shops/${collectionType}/promo-products-offers`, { params })
      .pipe(map((response: APIResponse<PromoCampaignsItemsResponse>) => response.payload));
  }

  public campaignToCampaignBanner(campaign: PromoCampaign): PromoCampaignBanner {
    const showBanner = !!this.globalStateService.getPromoCampaigns().map((pc) => pc.name === campaign.name); // !!campaign.enabled; // TODOs
    const campaignName = campaign.name;
    const priority = campaign.priority;
    const points = campaign.marketPlacePointsAmount;
    const multiplier = campaign.multiplier;

    // texts
    const name = getPromoText(campaign.texts, 'name') ?? campaignName;
    const checkoutBannerTitle = getPromoText(campaign.texts, 'checkoutBannerTitle');
    const checkoutBannerSubtitle = getPromoText(campaign.texts, 'checkoutBannerSubtitle');
    const checkoutPaymentBannerTitle = getPromoText(campaign.texts, 'checkoutPaymentBannerTitle');
    const checkoutDescription = getPromoText(campaign.texts, 'checkoutDescription');
    const orderTitle = getPromoText(campaign.texts, 'orderTitle');
    const orderAcceptanceDescription = getPromoText(campaign.texts, 'orderAcceptanceDescription');

    // images
    const logo = generateImageSrc(getPromoCampaignImage(campaign, 'bannerLogo'));
    const checkoutBannerLogo = generateImageSrc(getPromoCampaignImage(campaign, 'checkoutBannerLogo'));
    const paymentDoubleBannerLeftSide = generateImageSrc(getPromoCampaignImage(campaign, 'paymentBannerLeftSide'));
    const paymentDoubleBannerRightSideBorder = generateImageSrc(
      getPromoCampaignImage(campaign, 'paymentBannerRightSideBorder')
    );
    const paymentExtraInfoLogo = generateImageSrc(getPromoCampaignImage(campaign, 'paymentBannerExtraInfo'));
    const paymentDoubleBannerCampaignLogo = generateImageSrc(
      getPromoCampaignImage(campaign, 'combinedBannerCampaignLogo')
    );
    return {
      logo,
      checkoutBannerLogo,
      priority,
      campaignName,
      name,
      showBanner,
      multiplier,
      points,
      checkoutBannerTitle,
      checkoutBannerSubtitle,
      checkoutPaymentBannerTitle,
      checkoutDescription,
      orderTitle,
      orderAcceptanceDescription,
      paymentDoubleBannerLeftSide,
      paymentDoubleBannerRightSideBorder,
      paymentExtraInfoLogo,
      paymentDoubleBannerCampaignLogo
    };
  }

  /**
   * Returns an array with promo campaigns
   * x4 (happy_hour) + x6 (new_users) return x4, x6
   * x4 (happy_hour) + low_order return low_order
   * x4 (happy_hour) + coupon specific return x4
   * @returns {PromoCampaign[]}
   */
  public getCampaignModalPromoCampaigns(): PromoCampaign[] {
    const activeCampaigns = this.getActivePromoCampaigns();
    if (!activeCampaigns?.length) return [];
    const filteredCampaigns = activeCampaigns.filter((campaign) => isCollectionCampaign(campaign));
    const newUsersCampaign = filteredCampaigns.find((campaign) => campaign.name === 'new_users');
    if (isLowOrderProbabilityUserWithCoupon(this.globalStateService.getUser()) && newUsersCampaign) {
      return [newUsersCampaign];
    }
    const availableCoupons = this.globalStateService.getAvailableCoupons();
    const newUsersCampaignOverriddenByCoupons = isPromoCampaignOverriddenByCouponSynergies(
      newUsersCampaign,
      availableCoupons
    );
    if (newUsersCampaignOverriddenByCoupons) {
      return filteredCampaigns.filter((campaign) => campaign.name !== 'new_users');
    }
    return filteredCampaigns;
  }

  public decorateShopWithPromos(shop: Shop): Shop {
    const shopActivePromoCampaigns = this.getActivePromoCampaigns().filter((pc) =>
      shop.promoCampaigns.includes(pc.name)
    );
    const regularPromoCampaigns = shopActivePromoCampaigns.filter(
      (pc) => pc.type !== 'merchant_sponsored' && !isPromoCampaignSpecial(pc)
    );
    const sortedSpecialPromoCampaigns = getSpecialCampaigns(shopActivePromoCampaigns);
    const bestMerchantPromoCampaign = getShopBestMerchantSponsoredCampaign(shop, shopActivePromoCampaigns);
    const sortedPromoCampaigns = sortPromoCampaigns(
      [bestMerchantPromoCampaign, ...sortedSpecialPromoCampaigns, ...regularPromoCampaigns].filter(Boolean)
    );

    const bestPromoCampaign = sortedPromoCampaigns?.length ? sortedPromoCampaigns[0] : null;

    const availableCoupons = this.globalStateService.getAvailableCoupons();
    const lowOrderBenefitUser = isLowOrderProbabilityUserWithCoupon(this.globalStateService.getUser());
    const lowOrderBenefit =
      lowOrderBenefitUser && shopActivePromoCampaigns.find((promoCampaign) => promoCampaign.name === 'new_users');

    const promoCoupons = getSpecificPromoCampaignCoupons(availableCoupons, sortedPromoCampaigns);

    const promo: ShopPromo = {
      promoCoupons,
      lowOrderBenefit,
      multiplierSum: getShopTileMultiplierSum([bestMerchantPromoCampaign, ...regularPromoCampaigns].filter(Boolean)),
      pointsSum: getShopTilePointsSum([bestMerchantPromoCampaign, ...regularPromoCampaigns].filter(Boolean)),
      activePromoCampaignsNames: sortedPromoCampaigns.map((pc) => pc.name),
      bestSpecialCampaign: sortedSpecialPromoCampaigns?.length ? sortedSpecialPromoCampaigns[0] : null,
      hideOffers: this.shouldHideShopOffers(shop, bestMerchantPromoCampaign),
      bestMultiplier: bestPromoCampaign ? bestPromoCampaign.multiplier ?? 0 : 0,
      bestPointsAmount: bestPromoCampaign ? bestPromoCampaign.marketPlacePointsAmount ?? 0 : 0,
      bestPriority: bestPromoCampaign ? bestPromoCampaign.priority ?? 0 : 0
    };
    return { ...shop, promo: this.decorateShopPromoWithVisuals(promo, shop) };
  }

  private decorateShopPromoWithVisuals(promo: ShopPromo, shop: Shop): ShopPromo {
    if (!promo || !shop) return;
    return {
      ...promo,
      firstGroupShortOptions: this.shortPromoVisualOptionsService.generateFirstGroupOptions(promo),
      secondGroupShortOptions: this.shortPromoVisualOptionsService.generateSecondGroupOptions(promo, shop),
      firstGroupLongOptions: this.longPromoVisualOptionsService.generateFirstGroupOptions(promo),
      secondGroupLongOptions: this.longPromoVisualOptionsService.generateSecondGroupOptions(promo, shop)
    };
  }

  public redecorateShopsWithSpecificPromoCouponSynergies(couponSynergies: string[], decorateShops: Shop[]): Shop[] {
    if (!decorateShops?.length) return [];
    if (!couponSynergies?.length) return decorateShops;

    return decorateShops.map((shop) => {
      if (!shop?.promo?.promoCoupons?.length) return shop;
      /*
      since lowOrderBenefit has a bigger priority
      than promoCoupons in promo options service
      we need to null it
      */
      const promoCoupons = shop.promo.promoCoupons.filter((coupon) => couponSynergies.includes(coupon.synergy));
      const shopPromo: ShopPromo = { ...shop.promo, promoCoupons, lowOrderBenefit: null };
      return { ...shop, promo: this.decorateShopPromoWithVisuals(shopPromo, shop) };
    });
  }

  private shouldHideShopOffers(shop: Shop, campaign: PromoCampaign): boolean {
    if (!campaign) return false;
    if (!shop.registeredCampaigns?.length) return false;
    const merchantSponsoredCampaign: ShopRegisteredCampaign = shop.registeredCampaigns.find(
      (rc) => rc.campaignName === campaign.name
    );
    if (!merchantSponsoredCampaign) return false;
    return merchantSponsoredCampaign.hideShopOffers;
  }

  public promoCampaignToShopSuggestionBanner(promoCampaign: PromoCampaign, shop?: Shop): ShopSuggestionBanner {
    if (!promoCampaign) return undefined;
    const { name: campaignName, type, priority } = promoCampaign;
    const title: string = getPromoText(promoCampaign.texts, 'shopPlacementTitle');
    const shopModalTitle: string = getPromoText(promoCampaign.texts, 'inShopModalTitle');
    const timerTitle = getPromoText(promoCampaign.texts, 'timerTitle');
    const inShopPlacementBannerText = getInShopPlacementBannerText(promoCampaign, 'inShopPlacementBannerText', shop);
    const suggestionDescription = inShopPlacementBannerText
      ? inShopPlacementBannerText
      : getPromoText(promoCampaign.texts, 'suggestionDescription');
    const shopModalDescription = getPromoText(promoCampaign.texts, 'inShopModalDescription');
    const multiplier = promoCampaign?.multiplier;
    const pointsAmount = promoCampaign.marketPlacePointsAmount;
    const image = generateImageSrc(getPromoCampaignImage(promoCampaign, 'inShopPlacementImage'));
    return {
      campaignName,
      title,
      shopModalTitle,
      timerTitle,
      suggestionDescription,
      shopModalDescription,
      multiplier,
      pointsAmount,
      image,
      type,
      priority
    };
  }

  private isPromoCampaignActive(promoCampaign: PromoCampaign): boolean {
    if (!isWithinWeekDays(promoCampaign.enabledOnWeekDays)) return false;
    if (!isEnabledByTimeRanges(promoCampaign.enabledOnTimeRanges)) return false;
    const userSegments = this.globalStateService.getUser()?.segments;
    if (!isItemEnabledForSegments(promoCampaign, userSegments)) return false;
    const isNewUser = this.userService.isNewUser();
    if (promoCampaign.onlyForNewUsers && !isNewUser) return false;
    return true;
  }

  public getPaymentCampaigns(
    shopBusinessVertical: BusinessVertical,
    consumableCampaigns?: PromoCampaign[]
  ): PromoCampaign[] {
    const promoCampaigns = consumableCampaigns || this.getActivePromoCampaigns();
    if (!promoCampaigns) return [];
    const paymentCampaigns = promoCampaigns.filter((campaign) => campaign.type === 'payment');
    if (!shopBusinessVertical) return paymentCampaigns;
    return paymentCampaigns.filter((campaign) => {
      if (!campaign.businessVertical) return true;
      return campaign.businessVertical === shopBusinessVertical;
    });
  }

  public getTopPaymentCampaigns(
    shopBusinessVertical: BusinessVertical,
    consumableCampaigns?: PromoCampaign[]
  ): PromoCampaign[] {
    const paymentCampaigns = this.getPaymentCampaigns(shopBusinessVertical, consumableCampaigns);
    return sortPaymentCampaigns(paymentCampaigns, MAXIMUM_PAYMENT_CAMPAIGNS_IN_VIEW);
  }

  public getMaximumPaymentCampaignsInView(): number {
    return MAXIMUM_PAYMENT_CAMPAIGNS_IN_VIEW;
  }

  public promoCampaignToCollectionCampaign(campaign: PromoCampaign, shops: Shop[]): CollectionCampaign {
    const alternativeProps =
      isLowOrderProbabilityUserWithCoupon(this.globalStateService.getUser()) && campaign.name === 'new_users';

    const titleKey = alternativeProps ? 'modalLowOrderProbabilityTitle' : 'modalTitle';
    const shortTabTitleKey = alternativeProps ? 'lowOrderProbabilityTabTitle' : 'shortTag';
    const longTabTitleKey = alternativeProps ? 'lowOrderProbabilityTabTitle' : 'longTag';
    const descriptionBenefitTextKey = alternativeProps ? 'lowOrderProbabilityActiveBadge' : 'longTag';
    const descriptionTemplateKey = alternativeProps ? 'modalLowOrderProbabilityDescription' : 'modalDescription';

    const campaignTabImageKey = alternativeProps ? 'lowOrderProbabilityTabImage' : 'tabImage';
    const campaignTabImage = getPromoCampaignImage(campaign, campaignTabImageKey);
    const tabImageUrl = generateImageSrc(campaignTabImage);

    const maximumShopsNumber = campaign.campaignModal.maximumShopsNumber;
    const eligibleShops = filterShopsByPromoCampaigns(shops, [campaign.name]);
    const filteredShops = eligibleShops.slice(0, maximumShopsNumber);
    const filter = campaign.filter;
    const countdown = Boolean(campaign.enabledOnTimeRanges?.length);

    return {
      campaignName: campaign.name,
      modalTitle: getPromoText(campaign.texts, titleKey),
      shortTabTitle: getPromoText(campaign.texts, shortTabTitleKey),
      longTabTitle: getPromoText(campaign.texts, longTabTitleKey),
      descriptionBenefit: getPromoText(campaign.texts, descriptionBenefitTextKey),
      descriptionTemplate: getPromoText(campaign.texts, descriptionTemplateKey),
      tabImageUrl,
      shops: filteredShops,
      filter,
      countdown
    };
  }
}
