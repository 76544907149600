import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import { APIResponse, APIError } from '@box-types';
import { filterEnabledItems, HomeSection, normalizeHomeSection, ObservableCacheDecorator } from '@box/utils';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import defaultHomeSections from '@box-data/default-home-sections.json';
import { UserService } from '@box-core/services/user.service';
import { SentryService } from '@box-core/services/sentry.service';
import { CouponsService } from './coupons.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

const SECTIONS_SESSION_EXPIRATION = 10 * 60 * 1000; // 10 minutes;
@Injectable({ providedIn: 'root' })
export class HomeSectionsService {
  private readonly BOX_API = environment.application.API_URL;
  private readonly homeSectionsSource = new BehaviorSubject<HomeSection[]>([]);
  public readonly homeSections$ = this.homeSectionsSource.asObservable();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private sentryService: SentryService,
    private globalStateService: GlobalStateService
  ) {}

  @ObservableCacheDecorator({ expirationTimeInMs: SECTIONS_SESSION_EXPIRATION })
  public fetchHomeSections$(): Observable<HomeSection[]> {
    return this.http.get(`${this.BOX_API}/sections`).pipe(
      map((response: APIResponse<{ sections: HomeSection[] }>) => response.payload.sections),
      catchError((response: APIError) => {
        this.sentryService.captureException(response, {
          domain: 'sections',
          domainDetails: 'Loading defaults',
          severity: 'warning'
        });
        return of(defaultHomeSections as HomeSection[]);
      })
    );
  }

  public getHomeSections$(): Observable<HomeSection[]> {
    return this.fetchHomeSections$().pipe(
      map((sections) => {
        const normalizedSections = sections.map((section) => normalizeHomeSection(section));
        const userSegments = this.globalStateService.getUser()?.segments ?? [];
        const availableCoupons = this.globalStateService.getAvailableCoupons();
        const filteredSections = filterEnabledItems(normalizedSections, userSegments, availableCoupons);
        return filteredSections.sort((a, b) => a.position - b.position);
      }),
      tap((homeSections) => {
        this.setHomeSections(homeSections);
      })
    );
  }

  public setHomeSections(sections: HomeSection[]): void {
    this.homeSectionsSource.next(sections);
  }

  public getHomeSections(): HomeSection[] {
    return this.homeSectionsSource.getValue();
  }
}
