import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@box-env/environment';
import {
  BoxSession,
  CosmoteIDTokenResponse,
  CosmoteIDTokenValidationResponse,
  CosmoteIDUserInfoResponse,
  CosmoteIDTokenConfig,
  APIResponse
} from '@box-types';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private BOX_API: string = environment.application.API_URL;

  public GUID: number;
  public BOX_TOKEN: string;
  public COSMOTE_ACCESS_TOKEN: string;
  public COSMOTE_REFRESH_TOKEN: string;

  constructor(private http: HttpClient) {
    const localGUID: string = window.localStorage.getItem('Box:guid');
    this.GUID = localGUID && Number(localGUID);
    this.BOX_TOKEN = window.localStorage.getItem('Box:token');
    this.COSMOTE_ACCESS_TOKEN = window.localStorage.getItem('Box:cosmoteToken');
    this.COSMOTE_REFRESH_TOKEN = window.localStorage.getItem('Box:cosmoteRefreshToken');
  }

  public get isAuthenticated(): boolean {
    return !!this.COSMOTE_ACCESS_TOKEN && !!this.BOX_TOKEN;
  }

  public initiateBoxSession(token: string): Observable<BoxSession> {
    return this.http.post<BoxSession>(this.BOX_API + '/users/initiateWebBoxSessionV2', { token });
  }

  public signOut(): void {
    this.clearBoxToken();
    this.clearCosmoteTokens();
    window.localStorage.removeItem('Box:address');
    window.localStorage.removeItem('Box:paymentType');
    window.localStorage.removeItem('Box:cartCollections');
    window.localStorage.removeItem('Box:kritikosGuestCard');
    window.localStorage.removeItem('Box:Box:wuPromoSession');
  }

  public getCosmoteToken(config: CosmoteIDTokenConfig): Observable<CosmoteIDTokenResponse> {
    const data: CosmoteIDTokenConfig = { ...config };
    return this.http
      .post(this.BOX_API + '/users/cosmoteid/v2/token', data)
      .pipe(map((response: APIResponse<{ data: CosmoteIDTokenResponse }>) => response.payload.data));
  }

  public refreshAccessToken(): Observable<CosmoteIDTokenResponse> {
    const config: CosmoteIDTokenConfig = { grantType: 'REFRESH_TOKEN', refreshToken: this.COSMOTE_REFRESH_TOKEN };
    return this.getCosmoteToken(config);
  }

  public validateCosmoteToken(token: string): Observable<CosmoteIDTokenValidationResponse> {
    const data = { token };
    return this.http
      .post(this.BOX_API + '/users/cosmoteid/v2/token/validate', data)
      .pipe(map((response: APIResponse<{ data: CosmoteIDTokenValidationResponse }>) => response.payload.data));
  }

  public setCosmoteTokens(data: CosmoteIDTokenResponse): void {
    this.COSMOTE_ACCESS_TOKEN = data.access_token;
    window.localStorage.setItem('Box:cosmoteToken', data.access_token);
    if (!data.refresh_token) return;
    this.COSMOTE_REFRESH_TOKEN = data.refresh_token;
    window.localStorage.setItem('Box:cosmoteRefreshToken', data.refresh_token);
  }

  public clearCosmoteTokens(): void {
    delete this.COSMOTE_ACCESS_TOKEN;
    delete this.COSMOTE_REFRESH_TOKEN;
    window.localStorage.removeItem('Box:cosmoteIDToken');
    window.localStorage.removeItem('Box:cosmoteToken');
    window.localStorage.removeItem('Box:cosmoteRefreshToken');
  }

  public setBoxToken(session: BoxSession): void {
    this.GUID = session.guid;
    this.BOX_TOKEN = session.token;
    window.localStorage.setItem('Box:guid', String(session.guid));
    window.localStorage.setItem('Box:token', session.token);
  }

  public clearBoxToken(): void {
    delete this.GUID;
    delete this.BOX_TOKEN;
    window.localStorage.removeItem('Box:guid');
    window.localStorage.removeItem('Box:token');
  }
}
