import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ShopMenuPageService } from '../../services';
import { catchError, debounceTime, finalize, iif, of, skip, Subscription, switchMap, tap } from 'rxjs';
import { Product, Shop, APIError, Offer } from '@box-types';
import { DialogService, LoaderService } from '@box-core/services';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ShopMenuSearchService } from './shop-menu-search.service';
import { ViewportRuler } from '@angular/cdk/scrolling';

@Component({
  selector: 'shop-menu-search',
  templateUrl: './shop-menu-search.component.html',
  styleUrls: ['./shop-menu-search.component.scss'],
  providers: [ShopMenuSearchService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopMenuSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public placeholder = 'product_search';
  @Output() public stateChange = new EventEmitter<boolean>();

  public loading: boolean;
  public searchTerm: string;
  public offers: Offer[];
  public products: Product[];
  public hasResults: boolean;
  public resultsMaxHeight: number;
  public categoryView: boolean;
  public resultsOpen: boolean;

  private shop: Shop;
  private searchTermSubscription: Subscription;
  private resultsSubscription: Subscription;
  private viewportRulerSubscription: Subscription;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
    private searchService: ShopMenuSearchService,
    private shopMenuPageService: ShopMenuPageService,
    private focusMonitor: FocusMonitor,
    private viewportRuler: ViewportRuler,
    private dialogService: DialogService,
    private loaderService: LoaderService
  ) {}

  @HostBinding('class') public hostClass = 'shop-menu-search';

  ngOnInit(): void {
    this.searchTerm = this.searchService.getSearchTerm();
    this.shop = this.searchService.getShop();
    this.categoryView = this.shop.categoryView;
    this.setResultsSubscription();
    this.setSearchTermSubscription();
  }

  ngAfterViewInit(): void {
    this.focusMonitor.monitor(this.elementRef, true).subscribe();
  }

  ngOnDestroy(): void {
    this.resultsSubscription?.unsubscribe();
    this.searchTermSubscription?.unsubscribe();
    this.viewportRulerSubscription?.unsubscribe();
    this.searchService.clearSearchTerm();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  public onFocus(): void {
    this.openResults();
  }

  public onEscape(): void {
    this.closeResults();
  }

  public clearSearch(): void {
    this.searchService.setSearchTerm('');
    this.closeResults();
  }

  public openResults(): void {
    this.resultsMaxHeight = this.searchService.getResultsContainerMaxHeight(this.elementRef.nativeElement);
    this.resultsOpen = true;
    this.stateChange.emit(true);
    this.setViewportRulerSubscription();
  }

  public closeResults(): void {
    this.resultsOpen = false;
    this.stateChange.emit(false);
    this.viewportRulerSubscription?.unsubscribe();
  }

  public onInput(searchTerm: string): void {
    this.searchService.setSearchTerm(searchTerm.trim());
  }

  public onOfferAdd(offer: Offer, itemsType: 'regular' | 'supermarket'): void {
    if (!this.categoryView) {
      this.shopMenuPageService.openOfferMyo$({ item: offer, itemsType });
      return;
    }
    this.loaderService.setState(true);
    this.changeDetectorRef.detectChanges();
    this.searchService
      .fetchOffer(offer._id)
      .pipe(
        finalize(() => {
          this.loaderService.setState(false);
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (item) => {
          this.shopMenuPageService.openOfferMyo$({ item, itemsType });
        },
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  public onOfferClick(index: number, offer: Offer, itemsType: 'regular' | 'supermarket'): void {
    if (!this.categoryView) {
      this.shopMenuPageService.openOfferMyo$({ item: offer, itemsType, index });
      return;
    }
    this.loaderService.setState(true);
    this.changeDetectorRef.detectChanges();
    this.searchService
      .fetchOffer(offer._id)
      .pipe(
        finalize(() => {
          this.loaderService.setState(false);
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (item) => {
          this.shopMenuPageService.openOfferMyo$({ item, itemsType, index });
        },
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  public onProductAdd(product: Product, itemsType: 'regular' | 'supermarket'): void {
    if (!this.categoryView) return this.shopMenuPageService.onProductAdd$({ item: product, itemsType });
    this.loaderService.setState(true);
    this.changeDetectorRef.detectChanges();
    this.searchService
      .fetchProduct(product._id)
      .pipe(
        finalize(() => {
          this.loaderService.setState(false);
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (item) => this.shopMenuPageService.onProductAdd$({ item, itemsType }),
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  public onProductClick(index: number, product: Product, itemsType: 'regular' | 'supermarket'): void {
    if (!this.categoryView) return this.shopMenuPageService.openProductMyo$({ item: product, itemsType, index });
    this.loaderService.setState(true);
    this.changeDetectorRef.detectChanges();
    this.searchService
      .fetchProduct(product._id)
      .pipe(
        finalize(() => {
          this.loaderService.setState(false);
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (item) => this.shopMenuPageService.openProductMyo$({ item, itemsType, index }),
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  public onProductRemove(product: Product, itemsType: 'regular' | 'supermarket'): void {
    if (!this.categoryView) {
      this.shopMenuPageService.onProductRemove$({
        item: product,
        itemsType
      });
      return;
    }
    this.loaderService.setState(true);
    this.changeDetectorRef.detectChanges();
    this.searchService
      .fetchProduct(product._id)
      .pipe(
        finalize(() => {
          this.loaderService.setState(false);
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (item) =>
          this.shopMenuPageService.onProductRemove$({
            item,
            itemsType
          }),
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  public onOfferRemove(offer: Offer, itemsType: 'regular' | 'supermarket'): void {
    if (!this.categoryView) {
      this.shopMenuPageService.onOfferRemove$({
        item: offer,
        itemsType
      });
      return;
    }
    this.loaderService.setState(true);
    this.changeDetectorRef.detectChanges();
    this.searchService
      .fetchOffer(offer._id)
      .pipe(
        finalize(() => {
          this.loaderService.setState(false);
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (item) =>
          this.shopMenuPageService.onOfferRemove$({
            item,
            itemsType
          }),
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private setResultsSubscription(): void {
    this.resultsSubscription = this.searchService.searchResultChanges$().subscribe((searchResults) => {
      this.offers = searchResults.offers;
      this.products = searchResults.products;
      const totalCounter = this.offers.length + this.products.length;
      this.hasResults = totalCounter > 0;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setSearchTermSubscription(): void {
    this.searchTermSubscription = this.searchService.searchTerm$
      .pipe(
        skip(1), // Skip the first value of the BehaviorSubject to avoid the initial load
        debounceTime(500), // Debounce the change event to avoid callbacks on each input event
        tap((searchTerm: string) => {
          this.searchTerm = searchTerm;
          this.loading = true; // Trigger the loader
          this.changeDetectorRef.detectChanges(); // Trigger Change Detection
        }),
        switchMap((searchTerm) =>
          iif(
            () => searchTerm.length > 2, // If the term is less than 3 characters, then we reset the results
            this.searchService.search(searchTerm).pipe(
              catchError((error: Error) => {
                this.dialogService.openErrorDialog(error); // Inform the user about the error
                return of({ offers: [], products: [] }); // Return an empty state after the error
              })
            ),
            of({ offers: [], products: [] }) // If the term is less than 3 chars, reset the state
          )
        )
      )
      .subscribe({
        next: (results) => {
          this.searchService.setFilteredOffers(results.offers);
          this.searchService.setFilteredProducts(results.products);
          this.loading = false; // Trigger the loader on completion
          this.changeDetectorRef.detectChanges(); // Trigger Change Detection
        }
      });
  }

  private setViewportRulerSubscription(): void {
    if (!this.viewportRulerSubscription || this.viewportRulerSubscription.closed === true) {
      this.viewportRulerSubscription = this.viewportRuler.change(16).subscribe(() => {
        this.resultsMaxHeight = this.searchService.getResultsContainerMaxHeight(this.elementRef.nativeElement);
        this.changeDetectorRef.detectChanges();
      });
    }
  }
}
