import { Injectable, OnDestroy } from '@angular/core';
import { Coupon } from '@box-types';
import dayjs from 'dayjs';
import { BehaviorSubject, Subscription, timer, Observable, distinctUntilChanged, skipWhile } from 'rxjs';
import { map, pairwise, filter } from 'rxjs/operators';
import { isPrerenderBrowser } from '@box/utils';
import { GlobalStateService } from '@box-core/services/global-state.service';

type CouponState = 'COUPON_ACTIVE' | 'COUPON_INACTIVE';

type CouponTimerStateType = {
  state: CouponState;
  countDown: number;
};

@Injectable({ providedIn: 'root' })
export class CouponTimerService implements OnDestroy {
  private timerSubscription: Subscription;

  private couponTimer = new BehaviorSubject<Record<string, CouponTimerStateType>>({});
  public readonly couponTimer$ = this.couponTimer.asObservable();

  constructor(private globalStateService: GlobalStateService) {}

  ngOnDestroy(): void {
    if (this.timerSubscription) this.timerSubscription.unsubscribe();
  }

  private setState(couponCode: string, state: CouponState): void {
    const currentState = this.couponTimer.getValue();
    currentState[couponCode] = { state, countDown: this.getCouponCountDown(couponCode) };
    this.couponTimer.next(currentState);
  }

  private setCountDown(couponCode: string, countDown: number): void {
    const currentState = this.couponTimer.getValue();
    currentState[couponCode] = { countDown, state: this.getCouponTimerState(couponCode) };
    this.couponTimer.next(currentState);
  }

  public activeCouponsBySynergyExist(coupons: Coupon[]): boolean {
    if (!coupons?.length) return false;
    return coupons.some((coupon) => this.getCouponTimerState(coupon.code) === 'COUPON_ACTIVE');
  }

  public getCouponTimerState(couponCode: string): CouponState {
    return this.couponTimer.getValue()[couponCode]?.state;
  }

  public getCouponTimerState$(couponCode: string): Observable<CouponState> {
    return this.couponTimer$.pipe(
      map((globalState: Record<string, CouponTimerStateType>) => {
        return globalState[couponCode]?.state;
      }),
      distinctUntilChanged()
    );
  }

  public whenCouponIsEnabled$(couponCode: string): Observable<[CouponState, CouponState]> {
    return this.getCouponTimerState$(couponCode).pipe(
      pairwise(),
      filter(([prev, curr]) => prev === 'COUPON_INACTIVE' && curr === 'COUPON_ACTIVE')
    );
  }

  public whenCouponIsExpired$(couponCode: string): Observable<[CouponState, CouponState]> {
    return this.getCouponTimerState$(couponCode).pipe(
      pairwise(),
      filter(([prev, curr]) => prev === 'COUPON_ACTIVE' && curr === 'COUPON_INACTIVE')
    );
  }

  public getCouponCountDown(couponCode: string): number {
    return this.couponTimer.getValue()[couponCode]?.countDown;
  }

  public getCouponCountDown$(couponCode: string): Observable<number> {
    return this.couponTimer$.pipe(
      skipWhile(() => this.getCouponTimerState(couponCode) !== 'COUPON_ACTIVE'),
      map((globalState: Record<string, CouponTimerStateType>) => {
        return globalState[couponCode]?.countDown;
      })
    );
  }

  // todo: low order (next & seperate commit)
  public initialize(coupons: Coupon[]): void {
    if (isPrerenderBrowser(window)) return;
    this.timerSubscription?.unsubscribe();
    const couponsWithExpirationDate = coupons.filter((coupon) => coupon?.expirationDate);
    if (!couponsWithExpirationDate?.length) return;
    this.updateState(couponsWithExpirationDate);
    const currentDate = dayjs();
    const preDelayMilliseconds = 1000 - currentDate.millisecond();
    this.timerSubscription = timer(preDelayMilliseconds, 1000).subscribe(() =>
      this.updateState(couponsWithExpirationDate)
    );
  }

  private updateState(coupons: Coupon[]): void {
    const currentDate = dayjs();
    const prevAvailableCoupons = this.globalStateService.getAvailableCoupons();

    coupons.map((coupon) => {
      const couponExpirationDate = dayjs(coupon.expirationDate);
      const remainingSeconds = couponExpirationDate.diff(currentDate, 'second');
      const isExpired = remainingSeconds <= 0;
      if (isExpired) {
        const previousState = this.getCouponTimerState(coupon.code);
        if (previousState === 'COUPON_ACTIVE') {
          const updatedCoupons = prevAvailableCoupons.filter((availableCoupon) => availableCoupon.code !== coupon.code);
          this.globalStateService.setAvailableCoupons(updatedCoupons);
        }
        this.setState(coupon.code, 'COUPON_INACTIVE');
      }
      if (!isExpired) {
        this.setCountDown(coupon.code, remainingSeconds);
        if (remainingSeconds > 0) this.setState(coupon.code, 'COUPON_ACTIVE');
      }
    });
  }
}
