import { Injectable } from '@angular/core';
import { DialogService, UserService } from '@box-core/services';
import {
  Shop,
  VerificationReason,
  MarketCardConnectDialogResponse,
  MarketCardValidationConfig,
  MarketCardValidationDialogResponse,
  MarketCardCreateDialogResponse,
  MarketCardDetails,
  MarketCardDialogResponse,
  MarketCardValidationDialogData,
  BoxOtpVerificationReason
} from '@box-types';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  MarketCardConnectDialogComponent,
  MarketCardValidationDialogComponent,
  MarketCardCreateDialogComponent,
  MarketCardDialogComponent
} from '@box-market-widget/components';
import {
  PhoneVerificationDialogData,
  PhoneVerificationDialogResponse
} from '@box-shared/components/phone-verification-dialog/phone-verification-dialog.types';
import { PhoneVerificationDialogComponent } from '@box-shared/components';
import { Observable, switchMap, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable({ providedIn: 'any' })
export class MarketLoyaltyDialogsService {
  constructor(
    private dialogService: DialogService,
    private userService: UserService,
    private globalStateService: GlobalStateService
  ) {}

  public openMarketCardConnectDialog(shop: Shop): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog-fit-content',
      data: { shop }
    };

    return this.dialogService
      .openDialog(MarketCardConnectDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        switchMap((data: MarketCardConnectDialogResponse) => {
          if (!data) return of(false);
          const { search, needsPhoneVerificationWithReason, create, validationConfig, saveCard, guest } = data;
          // on search
          if (search) return this.openMarketCardValidationDialog(shop, validationConfig, saveCard, 'login');
          // users is missing msisdn with efresh and masoutis
          if (needsPhoneVerificationWithReason) {
            return this.openPhoneVerificationDialog(shop, needsPhoneVerificationWithReason);
          }
          // on create with phone already verified
          if (create) return this.openMarketCardCreateDialog(shop);
          if (guest) return of(true);
        })
      );
  }

  private openMarketCardValidationDialog(
    shop: Shop,
    validationConfig: MarketCardValidationConfig,
    saveCard: boolean,
    reason: BoxOtpVerificationReason
  ): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog-fit-content',
      data: { shop, validationConfig, saveCard, reason } as MarketCardValidationDialogData
    };

    return this.dialogService
      .openDialog(MarketCardValidationDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        switchMap((data: MarketCardValidationDialogResponse) => {
          if (!data?.success) return of(false);
          return this.saveAndShowCard(shop, data.save, data.cardDetails, false);
        })
      );
  }

  public openPhoneVerificationDialog(
    shop: Shop,
    reason: VerificationReason,
    mobilePhone?: string
  ): Observable<boolean> {
    const phoneVerificationDialogData: PhoneVerificationDialogData = {
      autoGenerate: true,
      mobilePhone,
      verificationReason: reason
    };
    const config: MatDialogConfig = { panelClass: 'box-dialog-fit-content', data: phoneVerificationDialogData };
    return this.dialogService
      .openDialog(PhoneVerificationDialogComponent, config)
      .afterClosed()
      .pipe(
        switchMap((data: PhoneVerificationDialogResponse) => {
          if (!data?.success) return of(false);
          const currentUser = this.globalStateService.getUser();
          this.globalStateService.setUser({ ...currentUser, ...data.verifyResponse });
          return this.openMarketCardCreateDialog(shop);
        })
      );
  }

  public openMarketCardCreateDialog(shop: Shop): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog-fit-content',
      data: { shop }
    };

    return this.dialogService
      .openDialog(MarketCardCreateDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        switchMap((data: MarketCardCreateDialogResponse) => {
          if (!data) return of(false);
          return this.saveAndShowCard(
            shop,
            data.saveCard,
            {
              cardId: data.cardId,
              customerToken: data.customerToken,
              points: data.points ?? 0,
              name: data.cardName
            },
            true
          );
        })
      );
  }

  private saveAndShowCard(
    shop: Shop,
    save: boolean,
    cardDetails: MarketCardDetails,
    created: boolean
  ): Observable<boolean> {
    if (save) {
      this.userService.saveLoyaltyCard(cardDetails, created);
    } else {
      this.userService.saveTempLoyaltyCard(cardDetails);
    }
    return this.openMarketCardDialog(shop, cardDetails, created);
  }

  private openMarketCardDialog(shop: Shop, cardDetails: MarketCardDetails, created: boolean): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog-fit-content',
      data: { shop, cardDetails, created }
    };

    return this.dialogService
      .openDialog(MarketCardDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        map((response: MarketCardDialogResponse) => {
          return response?.continue;
        })
      );
  }
}
