import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of, Subscriber, forkJoin } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService, UserService, DialogService, ExplorePromoCampaignService } from '@box-core/services';
import { User, UserEvent } from '@box-types';
import { PhoneVerificationDialogComponent } from '@box-shared/components';
import { MatDialogConfig } from '@angular/material/dialog';
import { PhoneVerificationDialogResponse } from '@box-shared/components/phone-verification-dialog/phone-verification-dialog.types';
import { isLowOrderProbabilityUser, appendMsisdnToUserAssets } from '@box/utils';
import { UserEventsService } from '@box-core/services/user-events.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable({ providedIn: 'root' })
export class UserResolver implements Resolve<User> {
  constructor(
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private explorePromoCampaignService: ExplorePromoCampaignService,
    private dialogService: DialogService,
    private userEventsService: UserEventsService,
    private globalStateService: GlobalStateService
  ) {}

  resolve(): Observable<User> {
    return new Observable((subscriber: Subscriber<User>) => {
      const BOX_TOKEN = this.authenticationService.BOX_TOKEN;

      if (!BOX_TOKEN) {
        this.userService.initUser();
        subscriber.next(this.globalStateService.getUser());
        subscriber.complete();
        return;
      }

      forkJoin({
        user: this.userService.fetchUser(),
        events: this.handleUserEvents$()
      })
        .pipe(
          switchMap(({ user }) => this.verifyUserPhone$(user)),
          switchMap((user) => this.handleUserAssets$(user)),
          switchMap((user) => this.handleLowOrderProbability$(user))
        )
        .subscribe({
          next: (user) => {
            this.userService.initUser(user);
            subscriber.next(this.globalStateService.getUser());
            subscriber.complete();
          },
          error: () => {
            this.authenticationService.clearCosmoteTokens();
            this.authenticationService.clearBoxToken();
            window.sessionStorage.removeItem('BOX:mb');
            this.userService.initUser();
            subscriber.next(this.globalStateService.getUser());
            subscriber.complete();
            void this.router.navigate(['/']);
          }
        });
    });
  }

  private handleUserEvents$(): Observable<UserEvent[]> {
    return this.userEventsService.fetchUserEventHistory().pipe(
      tap((events) => {
        this.userEventsService.setUserEventHistory(events);
      })
    );
  }

  private handleUserAssets$(user: User): Observable<User> {
    return this.userService.fetchCosmoteAssets().pipe(
      map((assets) => appendMsisdnToUserAssets(user, assets)),
      catchError(() => of([])),
      switchMap((assets) =>
        this.userService.fetchSegmentInfo(assets).pipe(
          map((segmentInfoResponse) => ({ ...user, ...segmentInfoResponse })),
          catchError(() => of(user))
        )
      )
    );
  }

  private handleLowOrderProbability$(user: User): Observable<User> {
    if (!isLowOrderProbabilityUser(user)) return of(user);
    return this.explorePromoCampaignService.fetchExploreCouponData().pipe(
      map((exploreData) => {
        const { coupon, userSegments } = exploreData;
        if (coupon) this.explorePromoCampaignService.initialize(coupon);
        return { ...user, segments: userSegments };
      }),
      catchError(() => of(user))
    );
  }

  private verifyUserPhone$(user: User): Observable<User> {
    return new Observable((subscriber: Subscriber<User>) => {
      if (!user.requiresMSISDNVerification) {
        subscriber.next(user);
        subscriber.complete();
      } else {
        const dialogConfig: MatDialogConfig = {
          disableClose: true,
          data: { verificationReason: 'mobile_phone_initialize' }
        };
        this.dialogService
          .openDialog(PhoneVerificationDialogComponent, dialogConfig)
          .afterClosed()
          .subscribe((response: PhoneVerificationDialogResponse) => {
            if (!response?.success || !response?.verifyResponse) {
              subscriber.error();
            } else {
              subscriber.next({ ...user, ...response.verifyResponse });
            }
            subscriber.complete();
          });
      }
    });
  }
}
