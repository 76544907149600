import { Injectable } from '@angular/core';
import {
  Product,
  Offer,
  PromoCampaign,
  ConfirmDialogResponse,
  CartItemType,
  Cart,
  CreateCartOptions,
  CartItemInstanceType,
  CartCollection
} from '@box-types';
import { BehaviorSubject, Observable } from 'rxjs';
import { DialogService } from './dialog.service';
import {
  cartHasCampaign,
  isStorageSupported,
  createCart,
  cartSupportsVolume,
  calculateCartVolume,
  calculateCartValues,
  getCartCollectionsFromStorage,
  collectionExpired,
  removeCartCollectionFromStorage,
  setCartCollectionToStorage,
  getCartInactiveOffers,
  isCartEmpty,
  updateCart,
  emptyCart,
  isProductIncludedInPromoCampaignCategory
} from '@box/utils';
import { LanguageService } from './language.service';
import { map } from 'rxjs/operators';
import { CartItemService } from '@box-core/services/cart-item.service';

/** Since the CartService is Global and it initialization depends on other services, we wanna
 * set the cart source to a default version to avoid handling undefined and null properties. When
 * we get rid of the Cart Service global usage, we are gonna initialize this state on the
 * Cart Component initialization. At that point we would have all the appropriate data we need
 * to initialize the service. */
const DEFAULT_CART = createCart({ shop: null });

@Injectable({ providedIn: 'root' })
export class CartService {
  public readonly cartSource = new BehaviorSubject<Cart>(DEFAULT_CART);
  public readonly cart$ = this.cartSource.asObservable();

  constructor(private dialogService: DialogService, private languageService: LanguageService) {
    this.checkExpiredCartCollections();
  }

  public initializeCart(options: CreateCartOptions): void {
    const updatedCart = createCart(options);
    this.calculatePricesAndUpdateCart(updatedCart);
  }

  public getCart(): Cart {
    return this.cartSource.getValue();
  }

  public addItemsToMainCart<I, N>(items: CartItemType<I>[], cartItemsService: CartItemService<I, N>): void {
    if (!items?.length) return;
    cartItemsService.setCartConfiguration({ cartSource: this.cartSource, saveCartToStorage: true });
    items?.map((item) => {
      const instances = [...(item.cartInstances ?? [])];
      item.cartQuantity = 0;
      delete item.cartInstances;
      instances?.map((itemInstance: CartItemInstanceType<N>) => {
        cartItemsService.addItem(item, itemInstance);
      });
    });
  }

  // the cartSubject property allows us to modify a state we specify
  // instead of cartSource
  public updateCart(cart: Cart, cartSubject?: BehaviorSubject<Cart>): void {
    const currentCart = cartSubject?.getValue() ?? this.cartSource.getValue();
    const updatedCart = updateCart(currentCart, cart);
    if (cartSubject) {
      cartSubject.next(updatedCart);
    } else {
      this.cartSource.next(updatedCart);
    }
  }

  /** The emptyCart method will empty the cart but keep the shop state untouched. Use
   * this method when you want to stay on the Shop View or Checkout View but want to clear
   * all the products from the Cart */
  public emptyCart(): void {
    const cart = this.cartSource.getValue();
    this.cartSource.next(emptyCart(cart));
    const collectionType = this.cartSource.getValue().shop?.collectionType;
    if (collectionType) removeCartCollectionFromStorage(collectionType, window.localStorage);
  }

  /** The clearCart method will empty the cart and reset the shop as well. Due to the fact that cart is
   * perfectly tied to the shop, in order for the state to clear completely, we need to reset the shop
   * as well. Use this method when you want to leave the Shop View or Checkout View completely */
  public clearCartAndShop(): void {
    const collectionType = this.cartSource.getValue().shop?.collectionType;
    this.cartSource.next(DEFAULT_CART);
    if (collectionType) removeCartCollectionFromStorage(collectionType, window.localStorage);
  }

  public isCartEmpty(): boolean {
    return isCartEmpty(this.cartSource.getValue());
  }

  public removeInactiveOffers(): void {
    const cart = this.cartSource.getValue();
    const inactiveOfferIds = getCartInactiveOffers(cart).map((offer) => offer._id);
    const updatedOffers = cart.offers.filter((offer) => !inactiveOfferIds.includes(offer._id));
    const updatedCart = { ...cart, offers: updatedOffers };
    this.calculatePricesAndUpdateCart(updatedCart);
    this.setLocalCartCollections(cart);
  }

  public calculatePricesAndUpdateCart(cart: Cart, cartSubject?: BehaviorSubject<Cart>): void {
    const updatedCart = calculateCartValues(cart);
    if (cartSupportsVolume(cart)) updatedCart.volume = calculateCartVolume(updatedCart);
    this.updateCart(updatedCart, cartSubject);
  }

  public showProductChoicesThresholdDialog(remainingChoices: number): void {
    const title = 'products_addition';
    const translatedMessage = this.languageService
      .getTextByKey(
        remainingChoices > 1
          ? 'should_add_more_ingredients_for_order_proceed'
          : 'should_add_one_more_ingredient_for_order_proceed'
      )
      .replace('_INGREDIENT_NUM', String(remainingChoices));

    const messages = [translatedMessage];
    this.dialogService.openInfoDialog({ title, messages });
  }

  // Can be delegated to CartService
  public showItemRemovalInfoDialog$(messages: string[]): Observable<boolean> {
    return this.dialogService
      .openConfirmDialog({ title: 'remove_from_cart', messages })
      .afterClosed()
      .pipe(map((response: ConfirmDialogResponse) => Boolean(response?.accepted)));
  }

  public showMaxCartLimitReachedDialog(): void {
    this.dialogService.openInfoDialog({
      title: 'maximum_delivery_limit',
      messages: ['products_maximum_allowed_volume_limit_reached']
    });
  }

  public showMaxItemLimitReachedDialog(): void {
    this.dialogService.openInfoDialog({
      title: 'maximum_product_purchase_limit',
      messages: ['product_maximum_purchase_limit_reached']
    });
  }

  public showOfferChoicesThresholdDialog(productNames: string[]): void {
    const translatedMessage = this.languageService
      .getTextByKey('should_add_ingredients_in_products_for_order_proceed')
      .replace('products_names', productNames.join(', '));
    this.dialogService.openInfoDialog({
      title: 'products_addition',
      messages: [translatedMessage]
    });
  }

  private checkExpiredCartCollections(): void {
    const cartCollections = getCartCollectionsFromStorage(window.localStorage);
    if (!cartCollections) return;
    const keys = Object.keys(cartCollections);
    for (const key of keys) {
      const expired = collectionExpired(cartCollections[key] as CartCollection);
      if (expired) removeCartCollectionFromStorage(Number(key), window.localStorage);
    }
  }

  public setLocalCartCollections(cart: Cart): void {
    const { shop, itemsQuantity, offers, products } = cart;
    const shouldRemove = !offers?.length && !products?.length;
    if (shouldRemove) return removeCartCollectionFromStorage(shop.collectionType, window.localStorage);
    const collection = { offers, products, timestamp: Date.now() };
    /** This will be changed when we do the refactor on the Products/Offers. Right now
    the size of the data that we store to the localStorage is way too much for the regular user.
    We have to reduce it to only options, so that we have no QuotaExceededError */
    if (!isStorageSupported()) return;
    setCartCollectionToStorage(shop.collectionType, collection, window.localStorage);
  }

  public cartHasPromoCampaign(promoCampaign: PromoCampaign): boolean {
    if (!promoCampaign) return false;
    const cartProducts: Product[] = this.getCart().products;
    const cartOffers: Offer[] = this.getCart().offers;
    return cartHasCampaign(promoCampaign, cartProducts, cartOffers);
  }

  public cartHasProductsThatBelongToCategory(promoCampaign: PromoCampaign): boolean {
    if (!promoCampaign?.cartSuggestion?.cuisines?.length) return false;
    const cartProducts = this.getCart().products;
    if (!cartProducts?.length) return false;
    const combatibleProducts = cartProducts.filter((p) => isProductIncludedInPromoCampaignCategory(promoCampaign, p));
    if (!combatibleProducts.length) return false;
    return true;
  }

  public getCartProductsAddedFromCheckout(): Product[] {
    const products = this.getCart().products;
    if (!products?.length) return [];
    return products.filter((p) => p?.cartInstances?.length && p.cartInstances.some((i) => i.addedFromCheckout));
  }

  public getCartOffersAddedFromCheckout(): Offer[] {
    const offers = this.getCart().offers;
    if (!offers?.length) return [];
    return offers.filter((o) => o?.cartInstances?.length && o.cartInstances.some((i) => i.addedFromCheckout));
  }
}
