import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AnalyticsService, CartService, DialogService, SentryService, ShopService } from '@box-core/services';
import { environment } from '@box-env/environment';
import {
  ConfirmDialogResponse,
  Product,
  Order,
  Offer,
  OfferAvailabilityOptions,
  ShopItemsAvailabilityApiOptions,
  ShopItemsAvailabilityResponse,
  APIError,
  APIResponse,
  ProductAvailabilityOptions,
  GANoAvailableProductConfig,
  CartCollection
} from '@box-types';
import { BoxConfirmDialogComponent, BoxInfoDialogComponent } from '@box-shared/components';
import {
  getCartOffersAvailabilityOptions,
  getCartProductsAvailabilityOptions,
  generateShopItemsAvailabilityApiOptions,
  copyOfferPropertiesAfterAvailabilityCheck,
  createOfferInstance,
  normalizeOffer,
  getOfferInstancePrice,
  getPreviousOrderOffersAvailabilityOptions,
  getPreviousOrderProductsAvailabilityOptions,
  copyProductPropertiesAfterAvailabilityCheck,
  createProductInstance,
  deleteProductInstancesComments,
  normalizeProduct,
  decorateOfferWithMaxItemsQuantity,
  decorateProductWithMaxItemsQuantity
} from '@box/utils';
import { cloneDeep } from 'lodash-es';
import { catchError, map, Observable, throwError } from 'rxjs';
import { CartProductService, CartOfferService } from '@box-core/services/cart-item.service';

@Injectable()
export class ShopMenuAvailabilityService {
  private readonly BOX_API = environment.application.API_URL;

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private shopService: ShopService,
    private sentryService: SentryService,
    private cartService: CartService,
    private cartProductsService: CartProductService,
    private cartOffersService: CartOfferService,
    private analyticsService: AnalyticsService
  ) {}

  public fetchItemsAvailability(options: ShopItemsAvailabilityApiOptions): Observable<ShopItemsAvailabilityResponse> {
    const params = new HttpParams({ fromObject: options.params });
    return this.http
      .post(`${this.BOX_API}/shops/${options.collectionType}/check-availability-products-offers`, options.body, {
        params
      })
      .pipe(map((response: APIResponse<ShopItemsAvailabilityResponse>) => response.payload));
  }

  public checkCartItemsAvailability(products: Product[], offers: Offer[]) {
    const productsAvailabilityOptions = getCartProductsAvailabilityOptions(products);
    const offersAvailabilityOptions = getCartOffersAvailabilityOptions(offers);
    return this.checkItemsAvailability(productsAvailabilityOptions, offersAvailabilityOptions);
  }

  public checkPreviousOrderItemsAvailability(order: Order): Observable<ShopItemsAvailabilityResponse> {
    const { products: orderProducts, offers: orderOffers } = order;
    const productsAvailabilityOptions = getPreviousOrderProductsAvailabilityOptions(orderProducts);
    const offersAvailabilityOptions = getPreviousOrderOffersAvailabilityOptions(orderOffers);
    return this.checkItemsAvailability(productsAvailabilityOptions, offersAvailabilityOptions);
  }

  public checkLocalCartCollectionAvailability(collection: CartCollection): Observable<ShopItemsAvailabilityResponse> {
    const { products: collectionProducts, offers: collectionOffers } = collection;
    const productsAvailabilityOptions = getCartProductsAvailabilityOptions(collectionProducts);
    const offersAvailabilityOptions = getCartOffersAvailabilityOptions(collectionOffers);
    return this.checkItemsAvailability(productsAvailabilityOptions, offersAvailabilityOptions);
  }

  public checkItemsAvailability(
    productsAvailabilityOptions: ProductAvailabilityOptions[],
    offersAvailabilityOptions: OfferAvailabilityOptions[]
  ): Observable<ShopItemsAvailabilityResponse> {
    const shop = this.shopService.getShop();
    const options = generateShopItemsAvailabilityApiOptions(
      shop,
      productsAvailabilityOptions,
      offersAvailabilityOptions
    );
    return this.fetchItemsAvailability(options).pipe(
      map((response: ShopItemsAvailabilityResponse) => {
        const { products: responseProducts, offers: responseOffers } = response;
        const finalProducts = responseProducts.map((product) => {
          const options = productsAvailabilityOptions.find((o) => o.pseudoProductId === product.pseudoProductId);
          return copyProductPropertiesAfterAvailabilityCheck(product, options);
        });
        const finalOffers = responseOffers.map((offer) => {
          const options = offersAvailabilityOptions.find((o) => o.pseudoOfferId === offer.pseudoOfferId);
          return copyOfferPropertiesAfterAvailabilityCheck(offer, options);
        });
        return { ...response, products: finalProducts, offers: finalOffers };
      }),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Shop Page',
          domainDetails: 'Shop Menu Availability Cart Check',
          severity: 'warning'
        });
        return throwError(() => error);
      })
    );
  }

  public handleItemsAvailabilityResponse(response: ShopItemsAvailabilityResponse): void {
    const { offers, products, notAvailableProductsMessage, modifiedProductsMessage } = response;
    this.shopService.clearMenuItemsQuantities();
    this.cartService.emptyCart();

    if (!notAvailableProductsMessage && !modifiedProductsMessage) return this.addItemsToCart(offers, products);

    if (!notAvailableProductsMessage && modifiedProductsMessage) {
      this.addItemsToCart(offers, products);
      this.showModifiedItemsDialog(modifiedProductsMessage);
      return;
    }

    if (notAvailableProductsMessage && !modifiedProductsMessage) {
      this.showItemsAvailabilityDialog(notAvailableProductsMessage)
        .afterClosed()
        .subscribe((data) => {
          if (!data) return;
          this.triggerAnalyticsEvent(data.accepted);
          if (!data.accepted) return;
          this.addItemsToCart(offers, products);
        });
      return;
    }

    if (notAvailableProductsMessage && modifiedProductsMessage) {
      this.showItemsAvailabilityDialog(notAvailableProductsMessage)
        .afterClosed()
        .subscribe((data) => {
          if (!data?.accepted) return;
          this.addItemsToCart(offers, products);
          this.showModifiedItemsDialog(modifiedProductsMessage);
        });
      return;
    }
  }

  private showItemsAvailabilityDialog(message: string): MatDialogRef<BoxConfirmDialogComponent, ConfirmDialogResponse> {
    const confirmDialogConfig = { messages: [message], confirmText: 'next_', cancelText: 'cancel_' };
    return this.dialogService.openConfirmDialog(confirmDialogConfig);
  }

  private showModifiedItemsDialog(message: string): MatDialogRef<BoxInfoDialogComponent> {
    const infoDialogConfig = { messages: [message] };
    return this.dialogService.openInfoDialog(infoDialogConfig);
  }

  private addItemsToCart(offers: Offer[], products: Product[]): void {
    const shop = this.shopService.getShop();
    const commentsAllowed = shop.isProductCommentsAllowed;

    for (const product of products) {
      const productCopy = cloneDeep(product);
      if (!commentsAllowed) delete productCopy.comments;
      const normalizedProduct = normalizeProduct(productCopy);
      const defaultDecoratedProduct = this.shopService.decorateProduct(normalizedProduct);
      const decoratedProductWithMaxItems = decorateProductWithMaxItemsQuantity(defaultDecoratedProduct, shop);
      const decoratedProductInstance = createProductInstance(decoratedProductWithMaxItems);
      const cartResponse = this.cartProductsService.addItem(decoratedProductWithMaxItems, decoratedProductInstance);
      if (cartResponse === 'ITEM_ADDED') {
        this.shopService.syncCartProductToMenu(decoratedProductWithMaxItems);
        this.shopService.addToCartProductAnalyticsEvent(normalizedProduct, decoratedProductInstance, 'recent_orders');
      }
    }

    for (const offer of offers) {
      const offerCopy = cloneDeep(offer);
      const normalizedOffer = normalizeOffer(offerCopy);
      const defaultDecoratedOffer = this.shopService.offerDecorator(normalizedOffer);
      const decoratedOfferWithMaxItems = decorateOfferWithMaxItemsQuantity(defaultDecoratedOffer, shop);
      const decoratedOfferInstance = createOfferInstance(decoratedOfferWithMaxItems);
      decoratedOfferInstance.groups.forEach((group) => {
        group.products.forEach((product) => {
          if (!commentsAllowed) deleteProductInstancesComments(product);
          if (product.checked) group.selectedProduct = product;
        });
      });
      decoratedOfferInstance.price = getOfferInstancePrice(decoratedOfferInstance);
      const cartResponse = this.cartOffersService.addItem(decoratedOfferWithMaxItems, decoratedOfferInstance);
      if (cartResponse === 'ITEM_ADDED') {
        this.shopService.syncCartOfferToMenu(decoratedOfferWithMaxItems);
        this.shopService.addToCartOfferAnalyticsEvent(normalizedOffer, decoratedOfferInstance, 'recent_orders');
      }
    }
  }

  private triggerAnalyticsEvent(accepted: boolean): void {
    const gaConfig = { selection: accepted ? 'synexeia' : 'akyro' } as GANoAvailableProductConfig;
    this.analyticsService.addGACustomEvent('no_available_product_user_option', gaConfig);
  }
}
