import { Injectable } from '@angular/core';
import { Product, Offer, Cart } from '@box-types';
import { syncOfferCartQuantityWithCart, syncProductCartQuantityWithCart, createCart } from '@box/utils';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ShopSuggestionDialogService {
  public readonly cartSource = new BehaviorSubject<Cart>(createCart());
  public readonly cart$ = this.cartSource.asObservable();

  constructor() {}

  public clearCart(): void {
    this.setCart(createCart());
  }

  public setCart(cart: Cart): void {
    this.cartSource.next(cart);
  }

  public syncProductCartQuantityWithCartState(product: Product): Product {
    const cart = this.cartSource.getValue();
    return syncProductCartQuantityWithCart(product, cart);
  }

  public syncOfferCartQuantityWithCartState(offer: Offer): Offer {
    const cart = this.cartSource.getValue();
    return syncOfferCartQuantityWithCart(offer, cart);
  }
}
