import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, catchError } from 'rxjs';

import { environment } from '@box-env/environment';
import { Address, ConfirmDialogResponse, APIResponse, APIError } from '@box-types';
import { map, switchMap, tap, finalize } from 'rxjs/operators';
import { PrivacyConsentService } from './privacy-consent.service';
import {
  decorateAddressWithDescription,
  areAddressesEqual,
  isAddressReadyForDelivery,
  decorateAddressWithUser,
  isAddressReadyForView,
  storageSet,
  storageGet,
  sortAddresses,
  normalizeAddress
} from '@box/utils';
import cloneDeep from 'lodash-es/cloneDeep';
import { AddressSelectDialogResponse } from '@box-shared/components/address-select-dialog/address-select-dialog.types';
import { AddressCreateDialogResponse } from '@box-shared/components/address-create-dialog/address-create-dialog.component.types';
import {
  AddressSelectDialogComponent,
  AddressCreateDialogComponent,
  AddressEditDialogComponent
} from '@box-shared/components';
import { DialogService } from '@box-core/services/dialog.service';
import { LoaderService } from '@box-core/services/loader.service';
import { AuthenticationService } from '@box-core/services/authentication.service';
import { CryptoService } from '@box-core/services/crypto.service';
import { AddressEditDialogResponse } from '@box-shared/components/address-edit-dialog/address-edit-dialog.component.types';
import { UserService } from '@box-core/services/user.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

// todo problematic tests
@Injectable({ providedIn: 'root' })
export class AddressesService {
  private BOX_API: string = environment.application.API_URL;

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    private privacyConsentService: PrivacyConsentService,
    private cryptoService: CryptoService,
    private userService: UserService,
    private globalStateService: GlobalStateService
  ) {
    this.initializeAddress();
  }

  private initializeAddress(): void {
    const addressCipherText = storageGet<string>(this.globalStateService.ADDRESS_STORAGE_KEY, window.localStorage);
    if (!addressCipherText) return;
    const address = this.cryptoService.decrypt(addressCipherText) as Address;
    if (!address) return;
    const normalizedAddress = normalizeAddress(address);
    // removing problematic address from clients' storage
    if (!isAddressReadyForView(normalizedAddress)) return this.globalStateService.clearAddress();
    this.globalStateService.address.next(normalizedAddress);
  }

  public getAddresses$(): Observable<Address[]> {
    const addresses = this.globalStateService.getAddresses();
    if (addresses?.length) return of(addresses);
    return this.fetchAddresses$().pipe(
      catchError((error: APIError) => {
        this.dialogService.openErrorDialog(error);
        return of([]);
      })
    );
  }

  private prepareIncomingAddresses(addresses: Address[]): Address[] {
    /* Block incoming problematic addresses from cosmote id
     * and decorate the rest */
    const addressesWithDescription = addresses.reduce((compatibleAddresses, address) => {
      if (!isAddressReadyForView(address)) return compatibleAddresses;
      const normalizedAddress = normalizeAddress(address);
      const decoratedAddress = decorateAddressWithDescription(normalizedAddress);
      compatibleAddresses.push(decoratedAddress);
      return compatibleAddresses;
    }, [] as Address[]);

    return sortAddresses(addressesWithDescription);
  }

  public setAndStoreAddress(address: Address): void {
    if (!address) return;
    this.globalStateService.address.next(address);
    const uxConsent: boolean = this.privacyConsentService.getPrivacyConsent()?.ux;
    if (!uxConsent) return;
    const addressCipherText = this.cryptoService.encrypt(address);
    storageSet<string>(this.globalStateService.ADDRESS_STORAGE_KEY, addressCipherText, window.localStorage);
  }

  public updateAddressInAddresses(address: Address): void {
    if (!address) return;
    const addresses: Address[] = cloneDeep(this.globalStateService.getAddresses());
    const index: number = addresses.findIndex((a) => a.addressId === address.addressId);
    if (index < 0) return;
    addresses[index] = address;
    this.globalStateService.setAddresses(addresses);
  }

  public addAddress(address: Address): void {
    if (!address) return;
    const addresses: Address[] = this.globalStateService.getAddresses() ?? [];
    // we can only have one short address at a time
    const readyForDeliveryAddresses = addresses.filter((address) => isAddressReadyForDelivery(address));
    this.globalStateService.setAddresses(sortAddresses([...readyForDeliveryAddresses, address]));
  }

  public removeAddress(address: Address): void {
    const addresses = this.globalStateService.getAddresses();
    const newAddresses = addresses.filter((a) => a.addressId !== address.addressId);
    this.globalStateService.setAddresses(newAddresses);
    const selectedAddress = this.globalStateService.getAddress();
    if (areAddressesEqual(selectedAddress, address)) this.globalStateService.clearAddress();
  }

  private fetchAddresses$(): Observable<Address[]> {
    return this.http.get(this.BOX_API + '/users/cosmoteid/addresses').pipe(
      map((response: APIResponse<{ addresses: Address[] }>) => {
        const addresses = response.payload.addresses;
        return this.prepareIncomingAddresses(addresses);
      })
    );
  }

  public createAddress$(address: Address): Observable<Address> {
    const decoratedAddress = decorateAddressWithUser(address, this.globalStateService.getUser());
    const data = { address: [decoratedAddress] };
    return this.http.post(this.BOX_API + '/users/cosmoteid/create/address', data).pipe(
      map((response: APIResponse<{ address: Address[] }>) => {
        const addresses = response.payload.address;
        return this.prepareIncomingAddresses(addresses)[0];
      })
    );
  }

  private updateAddress$(address: Address): Observable<Address> {
    const decoratedAddress = decorateAddressWithUser(address, this.globalStateService.getUser());
    const data = { address: [decoratedAddress] };
    return this.http.post(this.BOX_API + '/users/cosmoteid/update/address', data).pipe(
      map((response: APIResponse<{ address: Address[] }>) => {
        const addresses = response.payload.address;
        return this.prepareIncomingAddresses(addresses)[0];
      })
    );
  }

  private deleteAddress$(addressId: number): Observable<Address> {
    const data = { addressId };
    return this.http
      .post(this.BOX_API + '/users/cosmoteid/delete/address', data)
      .pipe(map((response: APIResponse<Address>) => response.payload));
  }

  public addressAlreadyExists(address: Address): boolean {
    const addresses: Address[] = this.globalStateService.getAddresses();
    if (!addresses?.length) return false;
    return addresses.some((a) => areAddressesEqual(a, address));
  }

  public initiateAddressSelectDialogFlow$(): Observable<AddressSelectDialogResponse | AddressCreateDialogResponse> {
    const addresses = this.globalStateService.getAddresses();
    return this.dialogService
      .openDialog(AddressSelectDialogComponent, {
        panelClass: 'box-dialog-small',
        data: { addresses }
      })
      .afterClosed()
      .pipe(
        switchMap((response: AddressSelectDialogResponse) => {
          if (!response?.create) return of(response);
          return this.initiateAddSmallAddressDialogFlow$();
        })
      );
  }

  public initiateAddSmallAddressDialogFlow$(): Observable<AddressCreateDialogResponse> {
    return this.openAddressCreateDialog$().pipe(
      tap((response: AddressCreateDialogResponse) => {
        if (!response?.address) return;
        this.addAddressToState(response.address);
      })
    );
  }

  public initiateAddFullAddressDialogFlow$(): Observable<AddressEditDialogResponse> {
    return this.openAddressCreateDialog$().pipe(
      switchMap((response) => {
        if (!response?.address) return of(null);
        return this.initiateEditAddressDialogFlow$(true, response.address);
      })
    );
  }

  public initiateEditAddressDialogFlow$(verbose: boolean, address: Address): Observable<AddressEditDialogResponse> {
    return this.dialogService
      .openDialog(AddressEditDialogComponent, {
        panelClass: 'box-dialog-fit-content',
        data: { address, verbose }
      })
      .afterClosed()
      .pipe(
        switchMap((response: AddressEditDialogResponse) => {
          // create can be triggered when we edit short addresses.
          // We need to create them on cosmote's side since they do not exist there yet.
          if (response?.action === 'create') {
            return this.initiateAddAddressFlow$(response.address).pipe(map(() => response));
          }

          if (response?.action === 'edit') {
            return this.initiateEditAddressFlow$(response.address).pipe(map(() => response));
          }

          return of(null);
        })
      );
  }

  public openAddressCreateDialog$(): Observable<AddressCreateDialogResponse> {
    return this.dialogService
      .openDialog(AddressCreateDialogComponent, {
        panelClass: 'box-dialog-fit-content'
      })
      .afterClosed()
      .pipe(map((response: AddressCreateDialogResponse) => response));
  }

  public initiateDeleteAddressDialogFlow$(address: Address): Observable<ConfirmDialogResponse> {
    return this.dialogService
      .openConfirmDialog({
        title: 'delete_address',
        messages: ['are_you_sure_for_address_deletion'],
        confirmText: 'confirm_',
        cancelText: 'cancel_'
      })
      .afterClosed()
      .pipe(
        tap((response: ConfirmDialogResponse) => {
          if (response?.accepted) this.initiateDeleteAddressFlow(address);
        })
      );
  }

  private initiateAddAddressFlow$(address: Address): Observable<Address> {
    if (!this.authenticationService.isAuthenticated || !isAddressReadyForDelivery(address)) {
      this.addAddressToState(address);
      return of(address);
    }
    if (this.addressAlreadyExists(address)) {
      this.setAndStoreAddress(address);
      return of(address);
    }
    this.loaderService.setState(true);
    return this.createAddress$(address).pipe(
      finalize(() => this.loaderService.setState(false)),
      tap((addr) => this.addAddressToState(addr)),
      catchError((err: APIError) => {
        this.dialogService.openErrorDialog(err);
        return of(address);
      })
    );
  }

  public addAddressToState(address: Address): void {
    this.addAddress(address);
    this.setAndStoreAddress(address);
  }

  private initiateEditAddressFlow$(address: Address): Observable<Address> {
    if (!this.authenticationService.isAuthenticated || !address.addressId) {
      this.setAndStoreAddress(address);
      return of(address);
    }
    this.loaderService.setState(true);
    return this.updateAddress$(address).pipe(
      finalize(() => this.loaderService.setState(false)),
      tap((address) => {
        this.updateAddressInAddresses(address);
        this.setAndStoreAddress(address);
      }),
      catchError((error: APIError) => {
        this.dialogService.openErrorDialog(error);
        return of(address);
      })
    );
  }

  private initiateDeleteAddressFlow(address: Address): void {
    if (!this.authenticationService.isAuthenticated || !address.addressId) {
      return this.removeAddress(address);
    }
    this.loaderService.setState(true);
    this.deleteAddress$(address.addressId)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: () => this.removeAddress(address),
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }
}
