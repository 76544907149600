<section class="checkout-page-section">
  <div class="container">
    <div class="checkout-container">
      <div class="checkout-summary">
        <h3 class="checkout-summary-title">{{ 'my_order' | translate }}</h3>
        <div class="checkout-block">
          <checkout-points
            *ngIf="showCheckoutPoints"
            [disabled]="checkoutPointsDisabled"
            [pointsToEurosRatio]="shop?.loyaltyPointsToRedeemRate"
            [euroRedemptionRate]="shop?.loyaltyRuleStepInEuroForRedemption"
            [cartPrice]="cartStartingPrice"
            [cartPriceWhichCanBeDiscounted]="cartPriceWithPureDiscounts"
            [totalDiscount]="totalDiscount"
          ></checkout-points>
          <checkout-market-points
            *ngIf="hasLoyaltyCard"
            class="checkout-market-points"
            [shop]="shop"
          ></checkout-market-points>
          <bank-points-redeem-toggle
            *ngIf="paymentType?.card?.cardDetailsAddedByBox?.loyalty?.canRedeem"
            [cartPrice]="cartPriceWithPureDiscounts"
            [cartPriceWhichCanBeDiscounted]="cartPriceWithPureDiscounts - totalDiscount"
            [card]="paymentType?.card"
          ></bank-points-redeem-toggle>
          <checkout-coupon class="checkout-coupon" [startingPrice]="priceCoveredByCoupon"></checkout-coupon>
          <payment-synergy-banner [selectedPaymentType]="paymentType"></payment-synergy-banner>
        </div>
        <div class="checkout-block">
          <h5 class="checkout-block-title">{{ 'payment_with' | translate }}</h5>
          <checkout-payment-types #paymentTypes [shop]="shop" [cartTotal]="totalPrice"></checkout-payment-types>
          <checkout-tip *ngIf="tipEnabled"></checkout-tip>
          <checkout-donation *ngIf="donationEnabled"></checkout-donation>
        </div>

        <div class="checkout-block">
          <h5 class="checkout-block-title">{{ 'delivery_time' | translate }}</h5>
          <delivery-method class="checkout-delivery-method"></delivery-method>
          <checkout-addresses [shop]="shop"></checkout-addresses>
          <div class="box-divider"></div>
          <ng-container *ngIf="showScheduledSection">
            <checkout-timeslot [shop]="shop"></checkout-timeslot>
            <div class="box-divider"></div>
          </ng-container>
        </div>
        <div *ngIf="hasInvoiceOption" class="checkout-block">
          <checkout-invoice class="checkout-invoice"></checkout-invoice>
          <div class="box-divider"></div>
        </div>
        <div *ngIf="bagsConfig?.hasPlasticBagSelection" class="checkout-block">
          <checkout-bags [bagsConfig]="bagsConfig"></checkout-bags>
          <div class="box-divider"></div>
        </div>
        <div *ngIf="hasReplacementOption" class="checkout-block">
          <checkout-product-replacement class="chaeckout-product-replacement"></checkout-product-replacement>
          <div class="box-divider"></div>
        </div>
        <div class="checkout-block">
          <h5 class="checkout-block-title">{{ 'contact_telephone' | translate }}</h5>
          <mat-form-field class="phone-field" [floatLabel]="'auto'">
            <input
              class="checkout-input"
              matInput
              [formControl]="phoneFormControl"
              cdkFocusRegionStart
              cdkFocusInitial
              aria-label="phone"
              [placeholder]="'type_a_contact_telephone' | translate"
            />
            <mat-error *ngIf="phoneFormControl?.errors?.required"
              >{{ 'contact_telephone_required' | translate }}</mat-error
            >
            <mat-error *ngIf="phoneFormControl?.errors?.invalidPhone"
              >{{ 'contact_telephone_is_invalid' | translate }}
            </mat-error>
          </mat-form-field>
          <div class="checkout-block-header">
            <h5 class="checkout-comment-title ods-typography-body-s-bold">{{ 'comment_' | translate }}</h5>
            <mat-slide-toggle
              color="primary"
              class="checkout-block-toggle"
              (change)="onChangeCommentSlider()"
            ></mat-slide-toggle>
          </div>
          <mat-form-field *ngIf="hasComment; else commentDivider" class="comment-field" [floatLabel]="'auto'">
            <textarea
              type="text"
              matInput
              [formControl]="commentFormControl"
              matTextareaAutosize="true"
              [placeholder]="'type_your_comment_here' | translate"
            ></textarea>
            <mat-hint align="end">{{ commentFormControl?.value?.length }} / 500</mat-hint>
            <mat-error *ngIf="commentFormControl?.errors?.invalid"
              >{{ 'comment_has_invalid_chars_message' | translate: { INVALID_CHARS:
              commentFormControl?.errors?.invalid.value } }}</mat-error
            >
            <mat-error *ngIf="commentFormControl?.errors?.maxlength"
              >{{ 'not_more_than_500_characters' | translate }}</mat-error
            >
          </mat-form-field>
          <ng-template #commentDivider>
            <div class="box-divider"></div>
          </ng-template>
        </div>

        <cart-button
          class="checkout-button"
          (click)="onOrderSubmit()"
          [pointsSum]="pointsSum"
          [multiplierSum]="multiplierSum"
          [disabled]="!canSubmitOrder"
          [quantity]="cartTotalQuantity"
          [price]="totalPrice / 100"
          >{{cartCtaTitle | translate}}</cart-button
        >
      </div>
      <div class="checkout-page-separator"></div>
      <div class="checkout-order-preview-container">
        <h4 class="checkout-order-preview-container-title ods-typography-subtitle-l">
          {{ 'my_products' | translate }}
        </h4>
        <checkout-suggestion-banners
          *ngIf="checkoutSuggestionBanners?.length"
          [checkoutSuggestionBanners]="checkoutSuggestionBanners"
        ></checkout-suggestion-banners>
        <div class="checkout-block">
          <checkout-order-preview></checkout-order-preview>
        </div>
      </div>
    </div>
  </div>
</section>
