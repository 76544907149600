import { ProductInstance, OfferInstance, CartItemInstance, CartItem } from '@box-types';
import { cloneDeep, isEqual } from 'lodash-es';

export {
  getProductInstanceComparableObject,
  isSameProductInstance,
  getOfferInstanceComparableObject,
  isSameOfferInstance,
  isSameInstance,
  getCartProductInstanceDescription,
  addInstanceToItem,
  updateInstanceToItem,
  removeInstanceFromItem
};

function getProductInstanceComparableObject(instance: ProductInstance): Partial<ProductInstance> {
  const obj = cloneDeep(instance);
  delete obj.quantity;
  delete obj.integrator;
  // Properties that we use for Client Side Calculations and UI
  delete obj.addedFromCheckout;
  delete obj.instanceId;
  delete obj.basePrice;
  delete obj.ingredientsPrice;
  return obj;
}

function isSameProductInstance(instance1: ProductInstance, instance2: ProductInstance): boolean {
  const obj1 = getProductInstanceComparableObject(instance1);
  const obj2 = getProductInstanceComparableObject(instance2);
  return isEqual(obj1, obj2);
}

function getOfferInstanceComparableObject(instance: OfferInstance): Partial<OfferInstance> {
  const obj = cloneDeep(instance);
  delete obj.quantity;
  delete obj.addedFromCheckout;
  const groups = obj.groups.map((group) => {
    delete group.products;
    // Offer Group Selected Product has only 1 instance
    const productInstance = group.selectedProduct?.cartInstances[0];
    if (!productInstance) return group;
    const selectedProduct = getProductInstanceComparableObject(productInstance);
    return { ...group, selectedProduct };
  });

  /*
  The Type Cast here is used due to the fact that Groups should contain ProductInstance
  and not Partial<productInstance>. We should look into it in the future for semantic
  improvement
  */
  return { ...obj, groups } as Partial<OfferInstance>;
}

function isSameOfferInstance(instance1: OfferInstance, instance2: OfferInstance): boolean {
  const obj1 = getOfferInstanceComparableObject(instance1);
  const obj2 = getOfferInstanceComparableObject(instance2);
  return isEqual(obj1, obj2);
}

function isSameInstance(instance1: CartItemInstance, instance2: CartItemInstance): boolean {
  /*
  We should create a function that checks for a specific Interafec in order to check if
  the instances are ProductInstance or OfferInstance. For now, that covers our case because
  the property groups is only available in Offers Instance
  */
  const isOfferInstance = Boolean((instance1 as OfferInstance).groups?.length);
  if (isOfferInstance) return isSameOfferInstance(instance1 as OfferInstance, instance2 as OfferInstance);
  return isSameProductInstance(instance1 as ProductInstance, instance2 as ProductInstance);
}

function getCartProductInstanceDescription(instance: ProductInstance, showPlaceholder = true): string {
  const commentPlaceholder = 'click_here_to_add_comment';
  const textComponents: string[] = [];
  if (instance.description) textComponents.push(instance.description);
  if (instance.comments) textComponents.push(`"${instance.comments}"`);
  if (textComponents?.length) return textComponents.join(', ');
  if (showPlaceholder) return commentPlaceholder;
  return '';
}

function addInstanceToItem<T>(item: T & CartItem, instance: CartItemInstance): T {
  const clonedItem = cloneDeep(item);
  if (!clonedItem.cartInstances) {
    clonedItem.cartInstances = [instance];
  } else {
    const cartInstance = clonedItem.cartInstances.find((i) => isSameInstance(i, instance));
    if (cartInstance) {
      cartInstance.quantity += instance.quantity;
    } else {
      clonedItem.cartInstances.push(instance);
    }
  }

  clonedItem.cartQuantity = clonedItem.cartInstances.reduce((acc, cur) => acc + cur.quantity, 0);
  return clonedItem;
}

function updateInstanceToItem<T>(item: T & CartItem, instance: CartItemInstance, updatedInstance: CartItemInstance): T {
  const clonedItem = cloneDeep(item);
  const cartInstanceIndex = clonedItem.cartInstances.findIndex((i) => isSameInstance(i, instance));
  clonedItem.cartInstances[cartInstanceIndex] = updatedInstance;
  clonedItem.cartQuantity = clonedItem.cartInstances.reduce((acc, cur) => acc + cur.quantity, 0);
  return clonedItem;
}

function removeInstanceFromItem<T>(item: T & CartItem, instance: CartItemInstance): T {
  const clonedItem = cloneDeep(item);
  const cartInstanceIndex = clonedItem.cartInstances.findIndex((i) => isSameInstance(i, instance));
  if (cartInstanceIndex === -1) return clonedItem;
  const cartInstance = clonedItem.cartInstances[cartInstanceIndex];
  if (cartInstance.quantity > 1) {
    cartInstance.quantity--;
  } else {
    clonedItem.cartInstances.splice(cartInstanceIndex, 1);
  }
  clonedItem.cartQuantity = clonedItem.cartInstances.reduce((acc, cur) => acc + cur.quantity, 0);
  return clonedItem;
}
