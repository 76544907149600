import { Injectable } from '@angular/core';
import { CouponsService, PromoCampaignsService } from '@box-core/services';
import { DiscoverFilter, PromoVisualOptions } from '@box-types';
import { couponSpecificPromoOptions, getSpecificPromoCampaignCoupons } from '@box/utils';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable()
export class HomeDiscoverFilterService {
  constructor(private promoCampaignsService: PromoCampaignsService, private globalStateService: GlobalStateService) {}

  public getDiscoverFilterCouponSpecificVisualOptions(filter: DiscoverFilter): PromoVisualOptions[] {
    const filterEligibleCouponSynergies = filter?.eligibleCouponSynergies;
    const filterHasNoEligibleCouponSynergies = !filterEligibleCouponSynergies?.length;
    if (filterHasNoEligibleCouponSynergies) return;

    const availableCoupons = this.globalStateService.getAvailableCoupons();
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();

    const couponsPromos = getSpecificPromoCampaignCoupons(availableCoupons, promoCampaigns);
    if (!couponsPromos?.length) return;

    const couponsFilterPromo = couponsPromos.filter((coupon) => filterEligibleCouponSynergies.includes(coupon.synergy));

    const couponVisualOptions = couponSpecificPromoOptions(couponsFilterPromo, 'shortTag');
    return [couponVisualOptions];
  }
}
