import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  GenerateOTPOptions,
  GenerateOTPResponse,
  VerifyOTPOptions,
  VerifyOTPResponse,
  OTPMethod,
  APIResponse
} from '@box-types';
import { environment } from '@box-env/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from '@box-core/services/user.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable({ providedIn: 'any' })
export class OtpService {
  private readonly BOX_API = environment.application.API_URL;

  constructor(private http: HttpClient, private globalStateService: GlobalStateService) {}

  public generateOTP(options: GenerateOTPOptions): Observable<GenerateOTPResponse> {
    return this.http
      .post(this.BOX_API + `/user/verify/generateOTP`, options)
      .pipe(map((response: APIResponse<GenerateOTPResponse>) => response.payload));
  }

  public verifyOTP(options: VerifyOTPOptions): Observable<VerifyOTPResponse> {
    return this.http
      .put(this.BOX_API + `/user/verify/verifyOTP`, options)
      .pipe(map((response: APIResponse<{ updatedUser: VerifyOTPResponse }>) => response.payload.updatedUser));
  }

  public generateOtpMethodObject(): OTPMethod {
    const user = this.globalStateService.getUser();
    if (!user) return;
    if (user.verifiedMSISDN) {
      return { msisdn: user.verifiedMSISDN };
    }
    if (user.email) {
      return { email: user.email };
    }
  }
}
